// import {
//   AuthenticationDetails,
//   CognitoUser,
//   CognitoUserAttribute,
//   CognitoUserPool,
// } from "amazon-cognito-identity-js";
import { Amplify, Auth, Hub } from 'aws-amplify';
// import { useDispatch } from "react-redux";
import actions from '../../state/actions';
import { store } from '../../state/store';

const ENV = process.env;

Amplify.configure({
  Auth: {
    // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
    // identityPoolId: ENV.REACT_APP_AWS_POOL_REGION,

    // REQUIRED - Amazon Cognito Region
    region: ENV.REACT_APP_AWS_POOL_REGION,

    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: ENV.REACT_APP_AWS_USER_POOL_ID,

    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: ENV.REACT_APP_AWS_USER_POOL_WEB_CLIENT_ID,

    // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
    // authenticationFlowType: 'USER_PASSWORD_AUTH',
  },
});

class AuthService {
  data: any = null;

  instance: any = null;

  constructor() {
    if (!this.instance) {
      this.instance = this;
    }

    const listener = (data: any) => {
      console.info(data);
      switch (data.payload.event) {
        case 'signIn':
          console.info('user signed in'); // [ERROR] My-Logger - user signed in
          break;
        case 'signUp':
          console.info('user signed up');
          break;
        case 'signOut':
          console.info('user signed out');
          break;
        case 'signIn_failure':
          console.info('user sign in failed');
          break;
        case 'configured':
          console.info('the Auth module is configured');
          break;
        default:
          console.info('Not Recognized');
          break;
      }
    };

    Hub.listen('auth', listener);
    // return this.instance;
  }

  async currentAuthenticatedUser() {
    return Auth.currentAuthenticatedUser();
  }

  async changePassword(oldPassword: string, newPassword: string) {
    const user = await Auth.currentAuthenticatedUser();
    return Auth.changePassword(user, oldPassword, newPassword);
  }

  async signIn(email: string, password: string) {
    try {
      const user = await Auth.signIn(email, password);
      store.dispatch({ type: actions.COGNITO_SIGN_IN, payload: user });
      return user;
    } catch (err: any) {
      if (err.code === 'UserNotConfirmedException') {
        // The error happens if the user didn't finish the confirmation step when signing up
        // In this case you need to resend the code and confirm the user
        // About how to resend the code and confirm the user, please check the signUp part

        try {
          await Auth.resendSignUp(email);
        } catch (error) {
          console.warn(error);
        }
      } else if (err.code === 'PasswordResetRequiredException') {
        // The error happens when the password is reset in the Cognito console
        // In this case you need to call forgotPassword to reset the password
        // Please check the Forgot Password part.
        try {
          await this.forgotPassword(email);
        } catch (error) {
          console.warn(error);
        }
      } else if (err.code === 'NotAuthorizedException') {
        // The error happens when the incorrect password is provided
      } else if (err.code === 'UserNotFoundException') {
        // The error happens when the supplied username/email does not exist in the Cognito user pool
      } else {
        console.warn(err);
      }

      throw err;
    }
  }

  async signUp({
    firstname,
    lastname,
    email,
    password,
    tacAccepted,
  }: {
    firstname: string;
    lastname: string;
    email: string;
    password: string;
    tacAccepted: boolean;
  }) {
    const data = await Auth.signUp({
      username: email,
      password,
      attributes: {
        email,
        'custom:firstname': firstname,
        'custom:lastname': lastname,
        'custom:signedin': 'false',
      },
      validationData: {
        tacAccepted: String(Date.now()),
      },
    });
    return data;
  }

  async forgotPassword(email: string) {
    return Auth.forgotPassword(email);
  }

  async forgotPasswordSubmit(email: string, code: string, password: string) {
    return Auth.forgotPasswordSubmit(email, code, password);
  }

  async verifyCurrentUserEmail(code: string) {
    return Auth.verifyCurrentUserAttributeSubmit('email', code);
  }

  async confirmSignUp(email: string, code: string) {
    return Auth.confirmSignUp(email, code, {
      // Optional. Force user confirmation irrespective of existing alias. By default set to True.
      forceAliasCreation: true,
    });
  }

  async signOut() {
    try {
      store.dispatch({ type: actions.COGNITO_SIGN_OUT, payload: null });
      await Auth.signOut({ global: true });
    } catch (err) {
      localStorage.clear();
    }
  }
}

const authService = new AuthService();
Object.freeze(authService);

export default authService;

