import React, { useState } from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

import { Button } from '@das-zahlwerk/dz-components';

import { InputField } from '@das-zahlwerk/dz-components';
import { AuthFeature } from '../../../components/features/auth-feature/auth-feature';
import authService from '../../../services/auth/auth.service';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import actions from '../../../state/actions';
import { Link } from '@das-zahlwerk/dz-components';
import { useTranslation } from 'react-i18next';

type FormData = {
  email: string;
  password: string;
};
const schema = yup
  .object({
    email: yup.string().email('Keine gültige E-Mail').required('E-Mail erforderlich'),
    password: yup.string().required('Passwort erforderlich'),
  })
  .required();

export function LoginView() {
  const history = useNavigate();
  const [actionError, setActionError] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<FormData>({
    mode: 'onSubmit', // CHECK
    reValidateMode: 'onSubmit', // CHECK
    resolver: yupResolver(schema),
  });

  const onSubmit = handleSubmit(async (data) => {
    try {
      setLoading(true);
      await authService.signIn(data.email, data.password);
      setLoading(false);
      reset();
      setActionError('');
      history('/manage');
    } catch (e: any) {
      setLoading(false);
      const errorCode =
        e?.message?.replace('PreAuthentication failed with error ', '')?.replace('.', '') ||
        e?.code;
      console.log('errorCode', errorCode);
      setActionError(errorCode);
      dispatch({ type: actions.COGNITO_SIGN_OUT, payload: null });
    }
  });

  return (
    <>
      <AuthFeature
        title='Logge dich jetzt ein'
        subTitle={
          <>
            oder <Link href='/auth/register'>jetzt registrieren</Link>
          </>
        }
      >
        <form className='space-y-6' onSubmit={onSubmit} noValidate>
          <InputField
            register={register}
            label='E-Mail'
            type='email'
            name='email'
            autoComplete='email'
            errors={errors}
          />
          <InputField
            register={register}
            label='Passwort'
            type='password'
            name='password'
            autoComplete='current-password'
            errors={errors}
          />

          <div className='flex justify-end'>
            <Link href='/auth/forgot-password'>Passwort vergessen?</Link>
          </div>

          <div>
            <Button type='submit' loading={loading} fullWidth>
              Einloggen
            </Button>
            {actionError && actionError.length > 0 && (
              <p className='mt-2 text-sm text-red-600'>
                {t(`errors.auth.${actionError.replace(':', '')}`)}
              </p>
            )}
          </div>
        </form>
      </AuthFeature>
    </>
  );
}

