import React, { useEffect } from 'react';
import { Card } from '@das-zahlwerk/dz-components';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../state/store';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import actions from '../../../state/actions';

interface IProps {}

export function WelcomeBackTile({}: IProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { user } = useSelector((s: RootState) => s.user);
  const { business, loading: businessLoading } = useSelector((s: RootState) => s.business);

  useEffect(() => {
    if (business && !businessLoading.includes(actions.GET_BUSINESS_REQUEST)) {
      dispatch({ type: actions.GET_BUSINESS_REQUEST });
    }
  }, []);
  return (
    <Card fullWidth>
      <div className='sm:flex sm:items-center sm:justify-between'>
        <div className='sm:flex sm:space-x-5'>
          {business?.businessLogoUrl && (
            <div className='flex-shrink-0 mt-5 sm:mt-0'>
              <img
                className='mx-auto h-20 w-20 rounded-full'
                src={business?.businessLogoUrl}
                alt=''
              />
            </div>
          )}
          <div className='mt-4 text-left sm:mt-0 sm:pt-1 sm:text-left'>
            <p className='text-sm font-medium text-gray-600'>{t('globals.titles.welcomeBack')},</p>
            <p className='text-xl font-bold text-gray-900 sm:text-2xl'>
              {user?.firstName} {user?.lastName}
            </p>
            <p className='text-sm font-medium text-gray-600'>{business?.businessName}</p>
          </div>
        </div>
        <div className='mt-5 flex justify-center sm:mt-0 mb-5 sm:mb-0'>
          <Link
            to='/manage/profile'
            className='flex justify-center items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50'
          >
            {t('globals.buttons.showProfile')}
          </Link>
        </div>
      </div>
    </Card>
  );
}

