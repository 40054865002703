/* eslint-disable default-param-last */
import { Business } from '@das-zahlwerk/dz-models';
import actions from '../actions';
import { removeFromArrayIfExists, addToArrayIfNotExists } from '../../helper';

interface IState {
  business?: Business;
  errors: string[];
  loading: string[];
}

const initialState: IState = {
  business: undefined,
  errors: [],
  loading: [],
};

export default function business(
  state: IState = initialState,
  action: { type: string; payload: any },
): IState {
  const { type, payload } = action;
  switch (type) {
    // GET
    case actions.GET_BUSINESS_REQUEST:
      return {
        ...state,
        loading: [...addToArrayIfNotExists(state.loading, actions.GET_BUSINESS_REQUEST)],
        errors: [...removeFromArrayIfExists(state.errors, actions.GET_BUSINESS_REQUEST)],
      };
    case actions.GET_BUSINESS_SUCCESS:
      return {
        ...state,
        business: new Business(payload),
        loading: [...removeFromArrayIfExists(state.loading, actions.GET_BUSINESS_REQUEST)],
      };
    case actions.GET_BUSINESS_ERROR:
      return {
        ...state,
        loading: [...removeFromArrayIfExists(state.loading, actions.GET_BUSINESS_REQUEST)],
        errors: [...addToArrayIfNotExists(state.errors, actions.GET_BUSINESS_REQUEST)],
      };

    // PATCH
    case actions.PATCH_BUSINESS_REQUEST:
      return {
        ...state,
        loading: [...addToArrayIfNotExists(state.loading, actions.PATCH_BUSINESS_REQUEST)],
        errors: [...removeFromArrayIfExists(state.errors, actions.PATCH_BUSINESS_REQUEST)],
      };
    case actions.PATCH_BUSINESS_SUCCESS:
      return {
        ...state,
        business: new Business({
          ...state.business,
          ...payload,
        }),
        loading: [...removeFromArrayIfExists(state.loading, actions.PATCH_BUSINESS_REQUEST)],
      };
    case actions.PATCH_BUSINESS_ERROR:
      return {
        ...state,
        loading: [...removeFromArrayIfExists(state.loading, actions.PATCH_BUSINESS_REQUEST)],
        errors: [...addToArrayIfNotExists(state.errors, actions.PATCH_BUSINESS_REQUEST)],
      };

    default:
      return state;
  }
}

