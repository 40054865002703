const t = {
  /**
   * Globals
   */
  globals: {
    buttons: {
      cancel: 'Abbrechen',
      confirm: 'Bestätigen',
      delete: 'Löschen',
      edit: 'Bearbeiten',
      save: 'Speichern',
      submit: 'Absenden',
      logout: 'Abmelden',
      login: 'Anmelden',
      resendMail: 'Mail erneut senden',
      showProfile: 'Profil anzeigen',
      newFinancingRequest: 'Neue Finanzierung anlegen',
      readMore: 'Mehr lesen',
      options: 'Optionen',
      sendViaEmail: 'Per E-Mail senden',
      sendViaSMS: 'Per SMS senden',
      sendViaManual: 'Als gesendet markieren',
      copyURL: 'Link kopieren',
    },
    titles: {
      welcomeBack: 'Willkommen zurück',
      dashboard: 'Dashboard',
      myProfile: 'Mein Profil',
      businessProfile: 'Firmenprofil',
      financingRequests: 'Finanzierungsanfragen',
      noFinancingRequests: 'Keine Finanzierungsanfragen',
      announcements: 'Ankündigungen',
      successModal: 'Erfolgreich!',
      errorModal: 'Fehler!',
      addFinancingRequest: 'Finanzierungsanfrage hinzufügen',
      financingRequestPreview: 'Vorschau deiner Finanzierungsanfrage',
      financingRequestDetails: 'Finanzierungsanfrage',
      quoteDetails: 'Angebotdetails',
      clientDetails: 'Kundendetails',
    },
    text: {
      emailNotVerifiedWarning: 'Deine E-Mail ist noch nicht bestätigt, bitte bestätige sie.',
      emptyFinancingRequests: 'Fange jetzt an neue Finanzierungsanfragen zu erstellen.',
      placeholder: 'Hier ist noch nichts!',
      emailWillBeVerified: 'Deine E-Mail wird geprüft.',
      emailSuccessfullyVerified: 'Deine E-Mail wurde verifiziert.',
      emailSuccessfullyVerifiedLoggedIn: 'Du wirst in Kürze weitergeleitet.',
      emailVerifiedError: 'Deine E-Mail konnte nicht verifiziert werden.',
      clientDetailsFR:
        'Versende ein Angebot inklusive optionaler Finanzierung an Deinen Kunden.',
    },
    labels: {
      requestName: 'Vorgangsbezeichnung',
      description: 'Beschreibung',
      firstName: 'Vorname',
      lastName: 'Nachname',
      clientFirstName: 'Kunde - Vorname',
      clientLastName: 'Kunde - Nachname',
      email: 'E-Mail',
      phone: 'Telefonnummer',
    },
    placeholders: {},
  },
  enums: {
    requestStatus: {
      undefined: 'Unbekannt',
      DRAFT: 'Entwurf',
      SENT: 'Versendet',
      PRELIMINARY_APPLICATION_SUCCESSFUL: 'Vorprüfung erfolgreich',
      LOAN_APPLICATION_SUCCESSFUL: 'Kreditanfrage erfolgreich',
      DONE: 'Erledigt',
      CANCELLED: 'Abgebrochen',
    },
    legalForm: {
      FREELANCER: 'Freiberufler',
      SOLE_TRADER: 'Einzelunternehmer / Selbstständig',
      LIMITED_PARTNERSHIP: 'oHG, KG, GbR, etc.',
      LIMITED: 'GmbH',
      LIMITED_LIABILITY_COMPANY: 'UG (haftungsbeschränkt)',
      STOCK_COMPANY: 'Aktiengesellschaft',
    },
  },
  dates: {},
  times: {},
  numbers: {
    currencyEUR: '{{val, currency(EUR)}}',
  },
  errors: {
    auth: {
      'Password did not conform with policy Password must have uppercase characters': 'Passwort stimmt nicht mit Passwort-Richtlinien überein. Ein Großbuchstabe ist erforderlich.',
      'Password did not conform with policy Password not long enough': 'Passwort muss mindestens 8 Zeichen lang sein.',
      'Password did not conform with policy Password must have lowercase characters': 'Passwort muss einen Kleinbuchstaben enthalten.',
      'Password did not conform with policy Password must have numeric characters': 'Passwort muss eine Zahl enthalten.',
      'Password did not conform with policy Password must have symbol characters': 'Passwort muss ein Sonderzeichen enthalten.',
      'Incorrect username or password': 'Ungültiger Benutzername oder Passwort',
      'EmailNotVerified': 'E-Mail nicht verifiziert',
    }
  },
  notifications: {
    emailVerificationMailSent: 'Es wurde eine E-Mail zur Verifizierung an Sie gesendet.',
    generalError:
      'Es gab ein Problem. Unser Team ist bereits informiert und wir arbeiten an einer Lösung.',
    copiedLink: 'Link wurde in die Zwischenablage kopiert.',
    'No Client Details given': 'Die E-Mail konnte nicht versendet werden, da keine Kontaktdaten angegeben wurden.',
  },
};

export default t;

 