import React, { useEffect, useState } from 'react';
import { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { uploadToS3 } from '../../../utils/upload-helper';
import { Button, Spinner } from '@das-zahlwerk/dz-components';
import axiosService from '../../../services/axios/axios.service';

interface IProps {
  businessLogoUrl?: string;
  setLogoUrl: (logoUrl: string | undefined) => void;
}

export function CompanyProfileDataLogoForm({
  businessLogoUrl,
  setLogoUrl: setFormLogoUrl,
}: IProps) {
  const [logoUrl, setLogoUrl] = useState<string | undefined>(businessLogoUrl);
  const [loading, setLoading] = useState<boolean>(false);
  useEffect(() => {
    if (businessLogoUrl) {
      setLogoUrl(businessLogoUrl);
    } else {
      setLogoUrl(undefined);
    }
  }, [businessLogoUrl]);

  const onDrop = useCallback(async (acceptedFiles: File[]) => {
    const acceptedFile = acceptedFiles[0];
    setLoading(true);
    const uploadToS3Response = await uploadToS3({
      fileType: acceptedFile.type,
      fileContents: acceptedFile,
      apiPath: '/business/logo',
      axiosInstance: axiosService.BUSINESS!,
    });
    setLoading(false);
    setLogoUrl(uploadToS3Response.fullPath);
    setFormLogoUrl(uploadToS3Response.fullPath);
    console.log(uploadToS3Response.fullPath);
  }, []);

  const { getRootProps } = useDropzone({ onDrop });
  console.log('businessLogoUrl', businessLogoUrl);
  return (
    <div className='flex flex-col gap-2'>
      <label className='block text-sm font-medium text-gray-700'>Logo</label>
      <div className='mt-1 sm:col-span-2 sm:mt-0'>
        <div className='flex items-center gap-5'>
          <span className='h-28 w-28 overflow-hidden bg-gray-100 flex items-center justify-center'>
            {!loading && logoUrl && logoUrl.length > 1 && (
              <img className='w-28 h-28 object-contain' src={logoUrl} alt='Logo' />
            )}
            {!loading && (!logoUrl || logoUrl.length <= 1) && (
              <svg className='h-28 w-28 text-gray-300' fill='currentColor' viewBox='0 0 24 24'>
                <path d='M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z' />
              </svg>
            )}
            {loading && <Spinner color='DARK' size='MEDIUM' />}
          </span>
          <div {...getRootProps()}>
            <Button disabled={loading} type='button'>
              {logoUrl ? 'Ändern' : 'Hochladen'}
            </Button>
          </div>
          {logoUrl && (
            <Button
              disabled={loading}
              type='button'
              color='secondary'
              onClick={() => {
                setLogoUrl('-');
                setFormLogoUrl('-');
              }}
            >
              Löschen
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}

