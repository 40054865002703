import { FinancingClient, FinancingRequest } from '@das-zahlwerk/dz-models';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Badge, Button } from '@das-zahlwerk/dz-components';
import { Card } from '@das-zahlwerk/dz-components';
import { Table } from '@das-zahlwerk/dz-components';
import actions from '../../../state/actions';
import { RootState } from '../../../state/store';
import { useTranslation } from 'react-i18next';
import EmptyState from '../../../components/empty-state/empty-state';
import { PlusIcon } from '@heroicons/react/20/solid';
import { useNavigate } from 'react-router-dom';
import { mapRequestStatusToColor } from '../../../utils/data-helper';
import { MODAL_NAMES } from '../../../components/modals/modals';

export function FinancingRequestsView() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { financingRequests, loading } = useSelector((s: RootState) => s.financingRequest);
  useEffect(() => {
    if (!loading.includes(actions.LIST_FINANCINGREQ_REQUEST)) {
      dispatch({
        type: actions.LIST_FINANCINGREQ_REQUEST,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Card
        title={t('globals.titles.financingRequests')}
        subTitle={t('globals.text.placeholder')}
        fullWidth
        cardActionTop={
          <Button
            type='button'
            onClick={() =>
              dispatch({
                type: actions.OPEN_MODAL,
                modal: MODAL_NAMES.FINANCING_REQUEST_ADD,
              })
            }
          >
            {' '}
            {t('globals.buttons.newFinancingRequest')}
          </Button>
        }
      >
        <Table<FinancingRequest>
          items={financingRequests}
          loading={loading.includes(actions.LIST_FINANCINGREQ_REQUEST)}
          columnDefinition={[
            {
              type: 'data',
              label: 'Vorgangsbezeichnung',
              attributeName: 'requestName',
            },
            {
              type: 'data',
              label: 'Kunde',
              attributeName: ['clientDetails'],
              itemClassName: '',
              className: '',
              transform: ([clientDetails]: FinancingClient[]) => {
                console.log(clientDetails);
                if (clientDetails) {
                  return `${clientDetails.firstName} ${clientDetails.lastName}`;
                }
                return '';
              },
            },
            {
              type: 'data',
              label: 'Status',
              attributeName: 'requestStatus',
              transform: (value) => {
                return (
                  <Badge color={mapRequestStatusToColor(value)}>
                    {t(`enums.requestStatus.${value}`)}
                  </Badge>
                );
              },
            },

            {
              type: 'data',
              label: 'Finanzierungsvolumen',
              attributeName: 'financeAmount',
              itemClassName: 'text-right',
              className: 'text-right',
              transform: (value) => t('numbers.currencyEUR', { val: value }),
            },
          ]}
          keyAttribute='requestId'
          rowAction={{
            attributeName: ['requestId'],
            onClick: (requestId: string) => {
              navigate(`/manage/financing-requests/${requestId}`);
            },
          }}
        />
        {financingRequests.length === 0 && !loading && (
          <EmptyState
            ctaPath='/manage/financing-requests/new'
            ctaText='newFinancingRequest'
            description='emptyFinancingRequests'
            title='noFinancingRequests'
            ctaIcon={<PlusIcon />}
          />
        )}
      </Card>
    </>
  );
}

