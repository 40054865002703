import axios, { AxiosInstance } from 'axios';
import authService from '../auth/auth.service';
// import { actions } from '../../state/actions';

const ENV = process.env;

interface IAxiosInstances {
  AUTH?: any;
  QUOTE?: any;
  FINANCINGREQ?: any;
  BUSINESS?: any;
}

class AxiosInstances implements IAxiosInstances {
  AUTH?: AxiosInstance = undefined;

  QUOTE?: AxiosInstance = undefined;
  
  FINANCINGREQ?: AxiosInstance = undefined;

  BUSINESS?: AxiosInstance = undefined;

  // instance?: AxiosInstances = undefined;

  constructor() {
    // if (!this.instance) {
    //   this.instance = this;
    // }
    Object.keys(ENV || {})
      .filter((item: string) => item.includes('REACT_APP_API_') && item.includes('_URL'))
      .map((key: string) => {
        const apiName = key.replace('REACT_APP_API_', '').replace('_URL', '');
        const apiUrl = ENV[key];
        const apiSecure =
          ENV[`REACT_APP_API_${apiName}_SECURE`] != null &&
          ENV[`REACT_APP_API_${apiName}_SECURE`] === 'true';
        (this as any)[apiName] = axios.create({
          baseURL: apiUrl,
          timeout: 20000,
        });

        (this as any)[apiName].interceptors.request.use(
          (req: any) =>
            new Promise((resolve) => {
              // CHeck secure
              if (apiSecure) {
                authService
                  .currentAuthenticatedUser()
                  .then((u: any) => {
                    req.headers.authorization = u?.signInUserSession?.idToken?.jwtToken;
                    resolve(req);
                  })
                  .catch(() => {
                    resolve(req);
                  });
              } else {
                resolve(req);
              }
            }),
          (error: any) => Promise.reject(error),
        );

        (this as any)[apiName].interceptors.response.use(
          (res: any) => res,
          (error: any) => Promise.reject(error),
        );

        return key;
      });
    // return this.instance; // ???
  }
}

const axiosService: AxiosInstances = new AxiosInstances();
Object.freeze(axiosService);

export default axiosService;

