import { User } from '@das-zahlwerk/dz-models';
import React, { useEffect } from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import actions from '../../../state/actions';
import { Button, CardActions, GridContainer, InputField } from '@das-zahlwerk/dz-components';
import { RootState } from '../../../state/store';

interface IProps {
  user?: User;
}

type FormData = {
  email: string;
  firstName: string;
  lastName: string;
};

const schema = yup
  .object({
    email: yup.string().email('Keine gültige E-Mail').required('E-Mail erforderlich'),
    firstName: yup.string().required('Vorname erforderlich'),
    lastName: yup.string().required('Nachname erforderlich'),
  })
  .required();

export function ProfileDataForm({ user }: IProps) {
  const { loading } = useSelector((s: RootState) => s.user);
  const dispatch = useDispatch();

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm<FormData>({
    resolver: yupResolver(schema),
    reValidateMode: 'onChange',
    defaultValues: user,
  });

  useEffect(() => {
    console.log('USER', user);
    if (user != null) {
      reset(user);
    }
  }, [user, reset]);

  const onSubmit = handleSubmit(async (data) => {
    try {
      dispatch({
        type: actions.PATCH_USER_REQUEST,
        payload: data,
      });
    } catch (e: any) {
      console.log('ERROR', e);
    }
  });

  return (
    <form className='flex flex-col divide-y space-y-6' onSubmit={onSubmit} noValidate>
      <GridContainer>
        <InputField
          register={register}
          label='Vorname'
          type='text'
          name='firstName'
          autoComplete='firstName'
          errors={errors}
          className='col-span-3 sm:col-span-6'
        />
        <InputField
          register={register}
          label='Nachname'
          type='text'
          name='lastName'
          autoComplete='lastName'
          errors={errors}
          className='col-span-3 sm:col-span-6'
        />
        <div className='col-span-12'>
          <InputField
            register={register}
            label='E-Mail'
            type='email'
            name='email'
            autoComplete='email'
            errors={errors}
            className='col-span-5 sm:col-span-7'
            disabled
            description='Wenn du deine E-Mail ändern möchtest, wende dich bitte an unseren Support.'
          />
        </div>
      </GridContainer>
      <CardActions>
        <Button
          type='submit'
          disabled={!isDirty}
          loading={loading.includes(actions.PATCH_USER_REQUEST)}
        >
          Speichern
        </Button>
      </CardActions>
    </form>
  );
}

