/* eslint-disable default-param-last */
import { Quote } from '@das-zahlwerk/dz-models';
import actions from '../actions';
import { mergeByKey, removeFromArrayIfExists, addToArrayIfNotExists } from '../../helper';

interface IState {
  quotes: Quote[];
  newQuoteId?: string;
  errors: string[];
  loading: string[];
}

const initialState: IState = {
  quotes: [],
  newQuoteId: undefined,
  errors: [],
  loading: [],
};

export default function quote(
  state: IState = initialState,
  action: { type: string; payload: any },
): IState {
  const { type, payload } = action;
  switch (type) {
    // GET
    case actions.GET_QUOTE_REQUEST:
      return {
        ...state,
        loading: [...addToArrayIfNotExists(state.loading, actions.GET_QUOTE_REQUEST)],
        errors: [...removeFromArrayIfExists(state.errors, actions.GET_QUOTE_REQUEST)],
      };
    case actions.GET_QUOTE_SUCCESS:
      return {
        ...state,
        quotes: [
          ...mergeByKey('resourceId', state.quotes, [payload]).sort(
            (a: Quote, b: Quote) => b.updatedAt! - a.updatedAt!,
          ),
        ],
        loading: [...removeFromArrayIfExists(state.loading, actions.GET_QUOTE_REQUEST)],
      };
    case actions.GET_QUOTE_ERROR:
      return {
        ...state,
        loading: [...removeFromArrayIfExists(state.loading, actions.GET_QUOTE_REQUEST)],
        errors: [...addToArrayIfNotExists(state.errors, actions.GET_QUOTE_REQUEST)],
      };
    // PATCH
    case actions.PATCH_QUOTE_REQUEST:
      return {
        ...state,
        loading: [...addToArrayIfNotExists(state.loading, actions.PATCH_QUOTE_REQUEST)],
        errors: [...removeFromArrayIfExists(state.errors, actions.PATCH_QUOTE_REQUEST)],
      };
    case actions.PATCH_QUOTE_SUCCESS:
      return {
        ...state,
        quotes: [...mergeByKey('resourceId', state.quotes, [payload])].sort(
          (a, b) => b.updatedAt - a.updatedAt,
        ),
        loading: [...removeFromArrayIfExists(state.loading, actions.PATCH_QUOTE_REQUEST)],
      };
    case actions.PATCH_QUOTE_ERROR:
      return {
        ...state,
        loading: [...removeFromArrayIfExists(state.loading, actions.PATCH_QUOTE_REQUEST)],
        errors: [...addToArrayIfNotExists(state.errors, actions.PATCH_QUOTE_REQUEST)],
      };

    // POST
    case actions.POST_QUOTE_REQUEST:
      return {
        ...state,
        loading: [...addToArrayIfNotExists(state.loading, actions.POST_QUOTE_REQUEST)],
        errors: [...removeFromArrayIfExists(state.errors, actions.POST_QUOTE_REQUEST)],
      };
    case actions.POST_QUOTE_SUCCESS:
      return {
        ...state,
        quotes: [...mergeByKey('resourceId', state.quotes, [payload])].sort(
          (a, b) => b.updatedAt - a.updatedAt,
        ),
        loading: [...removeFromArrayIfExists(state.loading, actions.POST_QUOTE_REQUEST)],
      };
    case actions.POST_QUOTE_ERROR:
      return {
        ...state,
        loading: [...removeFromArrayIfExists(state.loading, actions.POST_QUOTE_REQUEST)],
        errors: [...addToArrayIfNotExists(state.errors, actions.POST_QUOTE_REQUEST)],
      };
    
      // POST
    case actions.POST_UPLOADQUOTE_REQUEST:
      return {
        ...state,
        loading: [...addToArrayIfNotExists(state.loading, actions.POST_UPLOADQUOTE_REQUEST)],
        errors: [...removeFromArrayIfExists(state.errors, actions.POST_UPLOADQUOTE_REQUEST)],
      };
    case actions.POST_UPLOADQUOTE_SUCCESS:
      return {
        ...state,
        quotes: [...mergeByKey('resourceId', state.quotes, [payload])].sort(
          (a, b) => b.updatedAt - a.updatedAt,
        ),
        newQuoteId: payload.quoteId,
        loading: [...removeFromArrayIfExists(state.loading, actions.POST_UPLOADQUOTE_REQUEST)],
      };
    case actions.POST_UPLOADQUOTE_ERROR:
      return {
        ...state,
        loading: [...removeFromArrayIfExists(state.loading, actions.POST_UPLOADQUOTE_REQUEST)],
        errors: [...addToArrayIfNotExists(state.errors, actions.POST_UPLOADQUOTE_REQUEST)],
      };

    // LIST
    case actions.LIST_QUOTE_REQUEST:
      return {
        ...state,
        loading: [...addToArrayIfNotExists(state.loading, actions.LIST_QUOTE_REQUEST)],
        errors: [...removeFromArrayIfExists(state.errors, actions.LIST_QUOTE_REQUEST)],
      };
    case actions.LIST_QUOTE_SUCCESS:
      return {
        ...state,
        quotes: [...mergeByKey('resourceId', state.quotes, payload)].sort(
          (a, b) => b.updatedAt - a.updatedAt,
        ),
        loading: [...removeFromArrayIfExists(state.loading, actions.LIST_QUOTE_REQUEST)],
      };
    case actions.LIST_QUOTE_ERROR:
      return {
        ...state,
        loading: [...removeFromArrayIfExists(state.loading, actions.LIST_QUOTE_REQUEST)],
        errors: [...addToArrayIfNotExists(state.errors, actions.LIST_QUOTE_REQUEST)],
      };

    // DELETE
    case actions.DELETE_QUOTE_REQUEST:
      return {
        ...state,
        loading: [...addToArrayIfNotExists(state.loading, actions.DELETE_QUOTE_REQUEST)],
        errors: [...removeFromArrayIfExists(state.errors, actions.DELETE_QUOTE_REQUEST)],
      };
    case actions.DELETE_QUOTE_SUCCESS:
      return {
        ...state,
        quotes: [...state.quotes.filter((c) => c.resourceId !== payload?.resourceId)],
        loading: [...removeFromArrayIfExists(state.loading, actions.DELETE_QUOTE_REQUEST)],
      };
    case actions.DELETE_QUOTE_ERROR:
      return {
        ...state,
        loading: [...removeFromArrayIfExists(state.loading, actions.DELETE_QUOTE_REQUEST)],
        errors: [...addToArrayIfNotExists(state.errors, actions.DELETE_QUOTE_REQUEST)],
      };

    default:
      return state;
  }
}

