import { IFinancingRequest } from '@das-zahlwerk/dz-models';
import React, { useEffect } from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import actions from '../../../state/actions';
import { Button, CardActions, GridContainer, InputField, NumberField } from '@das-zahlwerk/dz-components';
import { RootState } from '../../../state/store';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

type FormData = Pick<IFinancingRequest, 'requestName' | 'description' | 'financeAmount'>;

const schema = yup
  .object({
    requestName: yup.string().required('Erforderlich'),
    financeAmount: yup.number().min(0, 'Bitte Zahl größer als 0 angeben').required('Erforderlich'),
  })
  .required();

export function AddFinancingRequestForm() {
  const { t } = useTranslation();
  const { financingRequests, loading } = useSelector((s: RootState) => s.financingRequest);
  const dispatch = useDispatch();
  const history = useNavigate();

  const {
    register,
    reset,
    handleSubmit,
    getValues,
    formState: { errors, isDirty },
  } = useForm<FormData>({
    resolver: yupResolver(schema),
    reValidateMode: 'onChange',
  });

  const requestName = getValues('requestName');
  useEffect(() => {
    if (requestName && !loading.includes(actions.POST_FINANCINGREQ_REQUEST)) {
      console.log('HIER SIND WIR', loading, financingRequests);
      const financingRequest = financingRequests.find((fR) => fR.requestName === requestName);
      if (financingRequest) {
        history(`/manage/financing-requests/${financingRequest.requestId}`);
        dispatch({ type: actions.CLOSE_MODAL });
        reset();
      }
    }
  }, [loading, financingRequests]);

  const onSubmit = handleSubmit(async (data) => {
    try {
      dispatch({
        type: actions.POST_FINANCINGREQ_REQUEST,
        payload: data,
      });
    } catch (e: any) {
      console.log('ERROR', e);
    }
  });

  return (
    <form className='flex flex-col divide-y space-y-6' onSubmit={onSubmit} noValidate>
      <GridContainer>
        <InputField
          register={register}
          tooltip={'Dieses Feld ist nur für dich, zur Erkennung des Auftrags bestimmt.'}
          label={t('globals.labels.requestName')}
          type='text'
          name='requestName'
          autoComplete='off'
          errors={errors}
          className='col-span-6 sm:col-span-12'
          placeholder='z.B Auftrags- oder Angebotsnummer'
        />
        <NumberField
          register={register}
          label='Finanzierungshöhe'
          type='number'
          name='financeAmount'
          autoComplete='off'
          errors={errors}
          className='col-span-6 sm:col-span-12'
          placeholder='4701,12'
          trailingText='€'
        />
      </GridContainer>
      <CardActions>
        <Button
          type='submit'
          disabled={!isDirty}
          loading={loading.includes(actions.POST_FINANCINGREQ_REQUEST)}
        >
          Erstellen
        </Button>
      </CardActions>
    </form>
  );
}

