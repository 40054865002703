import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import { DZUIRouting } from './components/routing/routing';
import authService from './services/auth/auth.service';
import actions from './state/actions';
import { RootState } from './state/store';
import TagManager, { TagManagerArgs } from 'react-gtm-module';

const tagManagerArgs: TagManagerArgs = {
  gtmId: process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID as string,
  preview: process.env.REACT_APP_GOOGLE_TAG_MANAGER_ENV as string,
  auth: process.env.REACT_APP_GOOGLE_TAG_MANAGER_AUTH as string,
};

TagManager.initialize(tagManagerArgs);

const App = () => {
  let history = useNavigate();
  const location = useLocation();
  const { signedIn, loading } = useSelector((s: RootState) => s.user);

  const dispatch = useDispatch();

  useEffect(() => {
    authService
      .currentAuthenticatedUser()
      .then((u) => {
        dispatch({ type: actions.COGNITO_SIGN_IN, payload: u });
      })
      .catch((e) => {
        console.log('ERROR', e);
        dispatch({ type: actions.COGNITO_SIGN_OUT });
      });
  }, []);

  useEffect(() => {
    if (signedIn || !loading.includes(actions.GET_USER_REQUEST)) {
      if (signedIn === false) {
        if (location.pathname.includes('/manage')) {
          history('/auth/login');
        }
      } else if (signedIn === true) {
        if (
          !location.pathname.includes('/manage') &&
          !location.pathname.includes('/auth/verify-email')
        ) {
          history('/manage');
        }
      } else {
        console.log('waiting for auth...');
      }
    }
  }, [signedIn]);

  return <DZUIRouting />;
};
export default App;

