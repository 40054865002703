import actions from '../actions';
import { mergeByKey } from '../../helper';
import { Notification, NotificationType } from '@das-zahlwerk/dz-models';
import { nanoid } from 'nanoid';

interface IState {
  lastNotification?: Notification;
  notifications: Notification[];
  errors: string[];
  loading: string[];
}

const initialState: IState = {
  lastNotification: undefined,
  notifications: [],
  errors: [],
  loading: [],
};

export default function notification(
  state: IState = initialState,
  action: { type: string; payload: Notification },
): IState {
  const { type, payload } = action;
  switch (type) {
    case actions.POST_RESEND_VERIFY_EMAIL_SUCCESS:
      const newNotificationSuccess = new Notification({
        key: 'emailVerificationMailSent',
        type: NotificationType.SUCCESS,
      });
      return {
        ...state,
        lastNotification: newNotificationSuccess,
        notifications: [
          ...mergeByKey('resourceId', state.notifications, [newNotificationSuccess]).sort(
            (a: any, b: any) => (new Date(b.startDate) as any) - (new Date(a.endDate) as any),
          ),
        ],
      };
    case actions.POST_RESEND_VERIFY_EMAIL_ERROR:
      const newNotificationError = new Notification({
        key: 'generalError',
        type: NotificationType.ERROR,
      });
      return {
        ...state,
        lastNotification: newNotificationError,
        notifications: [
          ...mergeByKey('resourceId', state.notifications, [newNotificationError]).sort(
            (a: any, b: any) => (new Date(b.startDate) as any) - (new Date(a.endDate) as any),
          ),
        ],
      };
    case actions.SEND_NOTIFICATION:
      if (payload && !payload?.resourceId) {
        payload.resourceId = nanoid();
      }
      return {
        ...state,
        lastNotification: payload,
        notifications: [
          ...mergeByKey('resourceId', state.notifications, [payload]).sort(
            (a: any, b: any) => (new Date(b.startDate) as any) - (new Date(a.endDate) as any),
          ),
        ],
      };
    default:
      return state;
  }
}

