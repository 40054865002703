/* eslint-disable default-param-last */
import { Client } from "@das-zahlwerk/dz-models";
import actions from "../actions";
import { mergeByKey, removeFromArrayIfExists, addToArrayIfNotExists } from "../../helper";

interface IState {
  clients: Client[];
  errors: string[];
  loading: string[];
}

const initialState: IState = {
  clients: [],
  errors: [],
  loading: [],
};

export default function client(
  state: IState = initialState,
  action: { type: string; payload: any }
): IState {
  const { type, payload } = action;
  switch (type) {
    // GET
    case actions.GET_CLIENT_REQUEST:
      return {
        ...state,
        loading: [...addToArrayIfNotExists(state.loading, actions.GET_CLIENT_REQUEST)],
        errors: [...removeFromArrayIfExists(state.errors, actions.GET_CLIENT_REQUEST)],
      };
    case actions.GET_CLIENT_SUCCESS:
      return {
        ...state,
        clients: mergeByKey("resourceId", state.clients, [payload]),
        loading: [...removeFromArrayIfExists(state.loading, actions.GET_CLIENT_REQUEST)],
      };
    case actions.GET_CLIENT_ERROR:
      return {
        ...state,
        loading: [...removeFromArrayIfExists(state.loading, actions.GET_CLIENT_REQUEST)],
        errors: [...addToArrayIfNotExists(state.errors, actions.GET_CLIENT_REQUEST)],
      };
    // PATCH
    case actions.PATCH_CLIENT_REQUEST:
      return {
        ...state,
        loading: [...addToArrayIfNotExists(state.loading, actions.PATCH_CLIENT_REQUEST)],
        errors: [...removeFromArrayIfExists(state.errors, actions.PATCH_CLIENT_REQUEST)],
      };
    case actions.PATCH_CLIENT_SUCCESS:
      return {
        ...state,
        clients: [...mergeByKey("resourceId", state.clients, [payload])].sort(
          (a, b) => b.updatedAt - a.updatedAt
        ),
        loading: [...removeFromArrayIfExists(state.loading, actions.PATCH_CLIENT_REQUEST)],
      };
    case actions.PATCH_CLIENT_ERROR:
      return {
        ...state,
        loading: [...removeFromArrayIfExists(state.loading, actions.PATCH_CLIENT_REQUEST)],
        errors: [...addToArrayIfNotExists(state.errors, actions.PATCH_CLIENT_REQUEST)],
      };

    // POST
    case actions.POST_CLIENT_REQUEST:
      return {
        ...state,
        loading: [...addToArrayIfNotExists(state.loading, actions.POST_CLIENT_REQUEST)],
        errors: [...removeFromArrayIfExists(state.errors, actions.POST_CLIENT_REQUEST)],
      };
    case actions.POST_CLIENT_SUCCESS:
      return {
        ...state,
        clients: mergeByKey("resourceId", state.clients, [payload]),
        loading: [...removeFromArrayIfExists(state.loading, actions.POST_CLIENT_REQUEST)],
      };
    case actions.POST_CLIENT_ERROR:
      return {
        ...state,
        loading: [...removeFromArrayIfExists(state.loading, actions.POST_CLIENT_REQUEST)],
        errors: [...addToArrayIfNotExists(state.errors, actions.POST_CLIENT_REQUEST)],
      };

    // LIST
    case actions.LIST_CLIENT_REQUEST:
      return {
        ...state,
        loading: [...addToArrayIfNotExists(state.loading, actions.LIST_CLIENT_REQUEST)],
        errors: [...removeFromArrayIfExists(state.errors, actions.LIST_CLIENT_REQUEST)],
      };
    case actions.LIST_CLIENT_SUCCESS:
      return {
        ...state,
        clients: mergeByKey("resourceId", state.clients, payload),
        loading: [...removeFromArrayIfExists(state.loading, actions.LIST_CLIENT_REQUEST)],
      };
    case actions.LIST_CLIENT_ERROR:
      return {
        ...state,
        loading: [...removeFromArrayIfExists(state.loading, actions.LIST_CLIENT_REQUEST)],
        errors: [...addToArrayIfNotExists(state.errors, actions.LIST_CLIENT_REQUEST)],
      };

    // DELETE
    case actions.DELETE_CLIENT_REQUEST:
      return {
        ...state,
        loading: [...addToArrayIfNotExists(state.loading, actions.DELETE_CLIENT_REQUEST)],
        errors: [...removeFromArrayIfExists(state.errors, actions.DELETE_CLIENT_REQUEST)],
      };
    case actions.DELETE_CLIENT_SUCCESS:
      return {
        ...state,
        clients: [...state.clients.filter((c) => c.resourceId !== payload?.resourceId)],
        loading: [...removeFromArrayIfExists(state.loading, actions.DELETE_CLIENT_REQUEST)],
      };
    case actions.DELETE_CLIENT_ERROR:
      return {
        ...state,
        loading: [...removeFromArrayIfExists(state.loading, actions.DELETE_CLIENT_REQUEST)],
        errors: [...addToArrayIfNotExists(state.errors, actions.DELETE_CLIENT_REQUEST)],
      };

    default:
      return state;
  }
}
