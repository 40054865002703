import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card } from '@das-zahlwerk/dz-components';
import actions from '../../../state/actions';
import { useEffect } from 'react';
import { RootState } from '../../../state/store';
import { ProfileDataForm } from '../../../components/forms/profile-form/profile-form';
import { Spinner } from '@das-zahlwerk/dz-components';

export function ProfileView() {
  const dispatch = useDispatch();
  const { user, loading: userLoading } = useSelector((s: RootState) => s.user);

  useEffect(() => {
    if (!userLoading.includes(actions.GET_USER_REQUEST)) {
      dispatch({
        type: actions.GET_USER_REQUEST,
      });
    }
  }, []);

  return (
    <>
      <Card
        title='Persönliche Daten'
        subTitle='Ändere hier deine persönlichen Informationen und Zugangsdaten für dein Profil.'
      >
        {user != null && !userLoading.includes(actions.GET_USER_REQUEST) ? (
          <ProfileDataForm user={user} />
        ) : (
          <div className='mt-6 flex justify-center'>
            <Spinner size='MEDIUM' color='DARK' />
          </div>
        )}
      </Card>
      <Card
        title='Account löschen'
        subTitle='Sie sind nicht zufrieden mit unserem Service? Schade, dass Sie uns verlassen möchten. Bitte kontaktieren Sie uns
          unter der E-Mail XYZ, wir nehmen dann den Löschvorgang vor.'
      >
        <div className='flex felx-col justify-end mt-6'>
          <Button>
            <a href='mailto:hallo@daszahlwerk.de'>Account löschen</a>
          </Button>
        </div>
      </Card>
    </>
  );
}

