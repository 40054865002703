import { applyMiddleware, combineReducers, legacy_createStore as createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';

import notification from './reducer/notification.reducer';
import permissions from './reducer/permissions.reducer';
import ui from './reducer/ui.reducer';
import user from './reducer/user.reducer';
import quote from './reducer/quote.reducer';
import business from './reducer/business.reducer';
import client from './reducer/client.reducer';
import financingRequest from './reducer/financing-request.reducer';

import rootSaga from './sagas';

const rootReducer = combineReducers({
  user,
  ui,
  permissions,
  notification,
  quote,
  business,
  client,
  financingRequest,
});


const sagaMiddleware = createSagaMiddleware();
export const store = createStore(rootReducer, applyMiddleware(sagaMiddleware));

sagaMiddleware.run(rootSaga);
export type RootState = ReturnType<typeof rootReducer>;

