import React, { useState } from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button } from '@das-zahlwerk/dz-components';

import { InputField } from '@das-zahlwerk/dz-components';
import { AuthFeature } from '../../../components/features/auth-feature/auth-feature';
import authService from '../../../services/auth/auth.service';
import { useNavigate } from 'react-router-dom';
import { CheckBox } from '@das-zahlwerk/dz-components';
import { Link } from '@das-zahlwerk/dz-components';
import { Transition } from '@headlessui/react';

type FormData = {
  email: string;
  password: string;
  firstname: string;
  lastname: string;
  tacAccepted: boolean;
};
const schema = yup
  .object({
    email: yup.string().email('Keine gültige E-Mail').required('E-Mail erforderlich'),
    password: yup.string().required('Passwort erforderlich'),
    firstname: yup.string().required('Vorname erforderlich'),
    lastname: yup.string().required('Nachname erforderlich'),
    tacAccepted: yup
      .boolean()
      .isTrue('Die Bedingungen müssen akzeptiert werden.')
      .required('Bitte zustimmen!'),
  })
  .required();

export function RegisterView() {
  const history = useNavigate();
  const [showFull, setShowFull] = useState(false);
  const [actionError, setActionError] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
  } = useForm<FormData>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    criteriaMode: 'firstError',
    resolver: yupResolver(schema),
  });

  const onSubmit = handleSubmit(async (data) => {
    setLoading(true);
    try {
      await authService.signUp(data);
      await authService.signIn(data.email, data.password);
      reset();
      setLoading(false);
      history('/manage/dashboard');
    } catch (e: any) {
      setLoading(false);
      const errorCode =
        e?.message?.replace('PreAuthentication failed with error ', '')?.replace('.', '') ||
        e?.code;
      console.log('errorCode', errorCode);
      setActionError(errorCode);
    }
  });

  const emailValue = watch('email');
  if (!showFull && emailValue && emailValue?.includes('@') && emailValue?.includes('.')) {
    setShowFull(true);
  }
  return (
    <>
      <AuthFeature
        title='Registriere dich jetzt'
        subTitle={
          <>
            oder <Link href='/auth/login'>logge dich ein</Link>
          </>
        }
      >
        <form className='space-y-6' onSubmit={onSubmit} noValidate>
          <InputField
            register={register}
            label='E-Mail'
            type='email'
            name='email'
            autoComplete='email'
            errors={errors}
          />
          <Transition
            appear={true}
            show={showFull ? true : false}
            enter='transition ease-in duration-100'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            className='space-y-6'
          >
            <InputField
              register={register}
              label='Vorname'
              type='text'
              name='firstname'
              autoComplete='firstname'
              errors={errors}
            />
            <InputField
              register={register}
              label='Nachname'
              type='text'
              name='lastname'
              autoComplete='lastname'
              errors={errors}
            />
            <InputField
              register={register}
              label='Passwort'
              type='password'
              name='password'
              autoComplete='new-password'
              errors={errors}
            />

            <div className='flex items-center justify-between'>
              <div className='flex items-center'>
                <CheckBox
                  register={register}
                  errors={errors}
                  label={
                    <>
                      Ich akzeptiere die{' '}
                      <Link href='https://www.daszahlwerk.de/datenschutz'>
                        Datenschutzerklärung
                      </Link>{' '}
                      und die{' '}
                      <Link href='https://www.daszahlwerk.de/agbs'>
                        Allgemeinen Geschäftsbedingungen
                      </Link>
                      .
                    </>
                  }
                  name='tacAccepted'
                />
              </div>
            </div>
          </Transition>

          <div>
            <Button type='submit' fullWidth loading={loading}>
              Registrieren
            </Button>
            {actionError && actionError.length > 0 && (
              <p className='mt-2 text-sm text-red-600'>
                {t(`errors.auth.${actionError.replace(':', '')}`)}
              </p>
            )}
          </div>
        </form>
      </AuthFeature>
    </>
  );
}

