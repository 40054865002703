import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card } from '@das-zahlwerk/dz-components';
import actions from '../../../state/actions';
import { useEffect } from 'react';
import { RootState } from '../../../state/store';
import { CompanyProfileDataForm } from '../../../components/forms/company-profile-form/company-profile-form';
import { Spinner } from '@das-zahlwerk/dz-components';

export function CompanyProfileView() {
  const dispatch = useDispatch();
  const { business, loading: businessLoading } = useSelector((s: RootState) => s.business);

  useEffect(() => {
    if (!businessLoading.includes(actions.GET_BUSINESS_REQUEST)) {
      dispatch({
        type: actions.GET_BUSINESS_REQUEST,
      });
    }
  }, []);

  return (
    <>
      <Card title='Betrieb' subTitle='Informationen zur Verifizierung des Betriebs.'>
        {business != null && !businessLoading.includes(actions.GET_BUSINESS_REQUEST) ? (
          <CompanyProfileDataForm business={business} />
        ) : (
          <div className='mt-6 flex justify-center'>
            <Spinner size='MEDIUM' color='DARK' />
          </div>
        )}
      </Card>
    </>
  );
}

