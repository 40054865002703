import React, { useEffect } from 'react';
import { Badge, Button, Card, Dropdown, IDropdownAction } from '@das-zahlwerk/dz-components';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../state/store';
import actions from '../../../../state/actions';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FinancingRequestForm } from '../../../../components/forms/financing-request-form/financing-request-form';
import { RequestStatus } from '@das-zahlwerk/dz-models';

import { mapRequestStatusToColor } from '../../../../utils/data-helper';
import { TrashIcon } from '@heroicons/react/20/solid';
import { MODAL_NAMES } from '../../../../components/modals/modals';

export function FinancingRequestDetailView() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useNavigate();
  const { financingRequests, loading } = useSelector((s: RootState) => s.financingRequest);
  const params = useParams<{ requestId: string }>();
  const { requestId } = params;
  const financingRequest = financingRequests.find((fr) => fr.requestId === requestId);

  useEffect(() => {
    if (!loading.includes(actions.GET_FINANCINGREQ_REQUEST)) {
      dispatch({
        type: actions.GET_FINANCINGREQ_REQUEST,
        payload: {
          requestId,
        },
      });
    }
  }, [requestId]);

  useEffect(() => {
    if (financingRequest == null && loading.length === 0) {
      dispatch({
        type: actions.OPEN_MODAL,
        modal: MODAL_NAMES.SUCCESS_MODAL,
        payload: {
          title: 'Deine Finanzierungsanfrage wurde gelöscht.',
          description: 'Du wirst zur Übersicht weitergeleitet.',
        },
      });
      history('/manage/financing-requests');
    }
  }, [financingRequest]);

  function getDropdownActions(): IDropdownAction[] {
    return [
      // {
      //   icon: <ArrowRightIcon className='w-5 h-5' />,
      //   name: 'Erneut versenden',
      //   onClick() {
      //     console.log('Erneut versenden');
      //   },
      // },
      // // TODO: 04.05. (Erneut versenden) - Modal mit Textfeld für E-Mail-Adresse
      {
        icon: <TrashIcon className='w-5 h-5' />,
        name: 'Löschen',
        onClick() {
          dispatch({
            type: actions.DELETE_FINANCINGREQ_REQUEST,
            payload: {
              requestId,
            },
          });
        },
      },
    ];
  }

  return (
    <>
      <Card
        title={t('globals.titles.clientDetails')}
        subTitle={t('globals.text.clientDetailsFR')}
        cardActionTop={
          <div className='flex gap-x-2 items-center'>
            <Badge color={mapRequestStatusToColor(financingRequest?.requestStatus)} size='large'>
              {t(`enums.requestStatus.${financingRequest?.requestStatus || RequestStatus.DRAFT}`)}
            </Badge>
            <Button
              type='button'
              disabled={financingRequest?.requestStatus !== RequestStatus.DRAFT} // TODO: 04.05. (Status prüfen)
              onClick={() => {
                dispatch({
                  type: actions.OPEN_MODAL,
                  modal: MODAL_NAMES.FINANCING_REQUEST_SENDPREV,
                  payload: { requestId },
                });
              }}
            >
              Jetzt senden
            </Button>
            <Dropdown name={t('globals.buttons.options')} actions={getDropdownActions()} />
          </div>
        }
      >
        <FinancingRequestForm financingRequest={financingRequest} />
      </Card>
    </>
  );
}

