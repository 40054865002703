import { all } from 'redux-saga/effects';
import userSagas from './sagas/user.saga';
import quoteSagas from './sagas/quote.saga';
import businessSagas from './sagas/business.saga';
import clientSagas from './sagas/client.saga';
import financingRequestsSagas from './sagas/financing-request.saga';

export default function* rootSaga() {
  yield all([
    ...userSagas,
    ...quoteSagas,
    ...businessSagas,
    ...clientSagas,
    ...financingRequestsSagas,
  ]);
}

