import { call, put, takeLatest } from 'redux-saga/effects';
import { NotificationType, Notification } from '@das-zahlwerk/dz-models';
import { formatUrl } from '../../helper';
import actions from '../actions';
import axiosService from '../../services/axios/axios.service';

const ENV = process.env;

function getDefaultUrl(a: actions): string {
  if (a === actions.GET_BUSINESS_REQUEST || a === actions.PATCH_BUSINESS_REQUEST) {
    return `/business`;
  }

  console.log(`Can't find action in user saga`);
  return '';
}

function* defaultRequest(action: { type: actions; payload: any; query: any }) {
  const { type, payload, query } = action;
  try {
    const data: { data: any; status: number } = yield call(axiosService.BUSINESS as any, {
      method: `${type.startsWith('LIST') ? 'get' : type.split('_')[0].toLocaleLowerCase()}`,
      url: formatUrl({
        baseUrl: ENV.API_BUSINESS_URL!,
        path: getDefaultUrl(type),
        queryParams: query,
      }),
      data: payload || null,
    });
    if (data?.status === 200) {
      yield put({
        type: type.replace('REQUEST', 'SUCCESS'),
        payload: data?.data?.items || data?.data,
      });
    }
  } catch (e: any) {
    yield put({ type: type.replace('REQUEST', 'ERROR'), payload: e.message });
    if (e?.response?.data?.code) {
      yield put({
        type: actions.SEND_NOTIFICATION,
        payload: new Notification({
          type: NotificationType.ERROR,
          key: e.response.data?.message || e.response.data.code,
        }),
      });
    }
  }
}

const sagas = [
  takeLatest(actions.GET_BUSINESS_REQUEST, defaultRequest),
  takeLatest(actions.PATCH_BUSINESS_REQUEST, defaultRequest),
];

export default sagas;

