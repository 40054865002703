import { IFinancingRequest, RequestStatus } from '@das-zahlwerk/dz-models';
import React, { useCallback, useEffect, useState } from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import actions from '../../../state/actions';
import {
  Button,
  CardActions,
  CardHeading,
  DropZone,
  GridContainer,
  InputField,
  NumberField,
} from '@das-zahlwerk/dz-components';
import { RootState } from '../../../state/store';
import { formatMobilePhone } from '../../../utils/data-helper';
import { useTranslation } from 'react-i18next';
import { PaperClipIcon, TrashIcon } from '@heroicons/react/20/solid';
import axiosService from '../../../services/axios/axios.service';
import { uploadToS3 } from '../../../utils/upload-helper';

interface IProps {
  financingRequest?: IFinancingRequest;
}

type FormData = Pick<
  IFinancingRequest,
  'requestName' | 'description' | 'financeAmount' | 'clientDetails' | 'quoteDetails'
>;

const schema = yup
  .object({
    financeAmount: yup.number().min(0, 'Bitte Zahl größer als 0 angeben').required('Erforderlich'),
    clientDetails: yup
      .object({
        firstName: yup.string().required('Erforderlich'),
        lastName: yup.string().required('Erforderlich'),
        email: yup.string().email().required('Erforderlich'),
        phone: yup.string(),
      })
      .shape({})
      .required('Erforderlich!'),
  })
  .required();

export function FinancingRequestForm({ financingRequest }: IProps) {
  const { t } = useTranslation();
  const { loading } = useSelector((s: RootState) => s.financingRequest);
  const [uploadLoading, setUploadLoading] = useState<boolean>(false);
  const dispatch = useDispatch();

  const {
    register,
    reset,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors, isDirty },
  } = useForm<FormData>({
    resolver: yupResolver(schema),
    reValidateMode: 'onChange',
    defaultValues: financingRequest,
  });

  useEffect(() => {
    console.log('financingRequest', financingRequest);
    if (financingRequest != null) {
      reset(financingRequest);
    }
  }, [financingRequest, reset]);

  const onDrop = useCallback(async (acceptedFiles: File[]) => {
    const acceptedFile = acceptedFiles[0];
    setUploadLoading(true);
    const uploadToS3Response = await uploadToS3({
      fileType: acceptedFile.type,
      fileContents: acceptedFile,
      apiPath: `business/financingRequests/${financingRequest!.requestId}/doc`,
      axiosInstance: axiosService.FINANCINGREQ!,
    });
    setUploadLoading(false);
    setValue('quoteDetails.quoteFile', uploadToS3Response.fullPath, {
      shouldValidate: true,
      shouldDirty: true,
      shouldTouch: true,
    });
    setValue('quoteDetails.quoteFileName', uploadToS3Response.cleanedFileName, {
      shouldValidate: true,
      shouldDirty: true,
      shouldTouch: true,
    });
    console.log(uploadToS3Response.fullPath);
  }, []);

  const onSubmit = handleSubmit(async (data) => {
    try {
      dispatch({
        type: actions.PATCH_FINANCINGREQ_REQUEST,
        payload: {
          requestId: financingRequest?.requestId,
          ...data,
        },
      });
    } catch (e: any) {
      console.log('ERROR', e);
    }
  });

  const [quoteFile, quoteFileName, quoteFilePreviewUrl] = getValues([
    'quoteDetails.quoteFile',
    'quoteDetails.quoteFileName',
    'quoteDetails.quoteFilePreviewUrl',
  ]);
  return (
    <form className='flex flex-col divide-y space-y-6' onSubmit={onSubmit} noValidate>
      <GridContainer>
        <InputField
          register={register}
          label={t('globals.labels.clientFirstName')}
          type='text'
          name='clientDetails.firstName'
          autoComplete='off'
          errors={errors}
          className='col-span-3 sm:col-span-6'
          placeholder='Max'
        />
        <InputField
          register={register}
          label={t('globals.labels.clientLastName')}
          type='text'
          name='clientDetails.lastName'
          autoComplete='off'
          errors={errors}
          className='col-span-3 sm:col-span-6'
          placeholder='Mustermann'
        />
        <InputField
          register={register}
          label={t('globals.labels.phone')}
          type='tel'
          name='clientDetails.phone'
          autoComplete='off'
          errors={errors}
          className='col-span-3 sm:col-span-6s pl-'
          placeholder='0171 1234567'
          // leadingText='+49'
          // inputClassName='pl-24'
          // onInput={(evt) => {
          //   evt.currentTarget.value = formatMobilePhone(evt.currentTarget.value);
          // }}
          // TODO
        />
        <InputField
          register={register}
          label={t('globals.labels.email')}
          type='string'
          name='clientDetails.email'
          autoComplete='off'
          errors={errors}
          className='col-span-3 sm:col-span-6'
          placeholder='max@mustermann.de'
        />
      </GridContainer>
      <div>
        <CardHeading title={t('globals.titles.quoteDetails')} />
        <GridContainer>
          <NumberField
            register={register}
            label='Betrag'
            description='Gib hier den Gesamtbetrag (inkl. MwSt.) ein, der auf Deinem Angebot steht.'
            type='number'
            name='financeAmount'
            autoComplete='off'
            errors={errors}
            className='col-span-12'
            trailingText='€'
            placeholder='4700,12'
          />
          <InputField
            register={register}
            label='Vorgangsbezeichnung (optional)'
            description={
              <>
                Nur für interne Zwecke, wird Deinem Kunden <span className='underline'>nicht</span>{' '}
                angezeigt.
              </>
            }
            type='text'
            name='requestName'
            className='col-span-6 sm:col-span-12'
            errors={errors}
            placeholder='z.B Auftrags- oder Angebotsnummer'
          />
          {/* TODO */}

          <div className='col-span-6 sm:col-span-12 flex flex-col'>
            <span className=''>Angebot</span>
            {quoteFile && quoteFileName && (
              <div className='py-4'>
                <dd className='mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0'>
                  <ul className='divide-y divide-gray-200 rounded-md border border-gray-200'>
                    <li className='flex items-center justify-between py-3 pl-3 pr-4 text-sm'>
                      <div className='flex w-0 flex-1 items-center'>
                        <PaperClipIcon
                          className='h-5 w-5 flex-shrink-0 text-gray-400'
                          aria-hidden='true'
                        />
                        <span className='ml-2 w-0 flex-1 truncate'>{quoteFileName}</span>
                      </div>
                      <div className='ml-4 flex gap-2'>
                        {quoteFilePreviewUrl && (
                          <a
                            href={quoteFilePreviewUrl}
                            target='_blank'
                            rel='noreferrer'
                            className='font-medium text-casal-600 hover:text-casal-500'
                          >
                            Vorschau
                          </a>
                        )}
                        <TrashIcon
                          className='h-5 w-5 flex-shrink-0 text-casal-600 hover:text-casal-500 cursor-pointer'
                          aria-hidden='true'
                          onClick={() => {
                            setValue('quoteDetails.quoteFile', undefined, {
                              shouldValidate: true,
                              shouldDirty: true,
                              shouldTouch: true,
                            });
                            setValue('quoteDetails.quoteFileName', undefined, {
                              shouldValidate: true,
                              shouldDirty: true,
                              shouldTouch: true,
                            });
                          }}
                        />
                      </div>
                    </li>
                  </ul>
                </dd>
              </div>
            )}
            <DropZone
              onUpload={onDrop}
              multiple={false}
              loading={uploadLoading}
              preDocumentText='Lade das Angebot hoch'
              postDocumentText='oder ziehe es in das Feld'
              allowedFileTypesDescription='Nur PDF-Dateien werden akzeptiert.'
              allowedFileTypeConfiguration={{
                'application/pdf': [],
              }}
            />
          </div>
        </GridContainer>
      </div>
      <CardActions>
        <div className='gap-2 flex'>
          <Button
            type='submit'
            color='secondary'
            disabled={!isDirty}
            loading={loading.includes(actions.PATCH_FINANCINGREQ_REQUEST)}
          >
            Speichern
          </Button>
        </div>
      </CardActions>
    </form>
  );
}

