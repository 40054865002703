import { HomeIcon, BuildingOfficeIcon, UserCircleIcon, PaperAirplaneIcon } from '@heroicons/react/24/outline';
import { IRoute } from './components/routing/routing';
import { AuthLayout } from './layouts/auth/auth.layout';
import { ManagementLayout } from './layouts/management/management.layout';
import { ForgotPasswordView } from './views/auth/forgot-password/forgot-password.view';
import { LoginView } from './views/auth/login/login.view';
import { RegisterView } from './views/auth/register/register.view';
import { ResetPasswordView } from './views/auth/reset-password/reset-password.view';
import { VerifyEmailView } from './views/auth/verify-email/verify-email.view';
import { Error404View } from './views/error/error-404/error-404.view';
import { CompanyProfileView } from './views/manage/company-profile/company-profile.view';
import { DashboardView } from './views/manage/dashboard/dashboard.view';
import { FinancingRequestsView } from './views/manage/financing-request/financing-requests.view';
import { FinancingRequestDetailView } from './views/manage/financing-request/financing-request[request-id]/financing-request[request-id].view';
import { ProfileView } from './views/manage/profile/profile.view';
// import { QuoteNewView } from './views/manage/quotes/quote-new/quote-new.view';
// import { QuotesView } from './views/manage/quotes/quotes.view';
// import { QuoteDetailView } from './views/manage/quotes/quote[quote-id]/quote[quote-id].view';

export const routes: IRoute[] = [
  {
    path: '/',
    redirect: '/auth/login',
    permissions: [],
    modules: [],
  },

  {
    path: '/manage',
    redirect: '/manage/dashboard',
    permissions: [],
    modules: [],
  },
  {
    path: '/manage/dashboard',
    layout: ManagementLayout,
    view: DashboardView,
    permissions: [],
    modules: [],
    title: 'dashboard',
    icon: HomeIcon,
    showInNavigation: true,
  },
  {
    path: '/manage/financing-requests',
    layout: ManagementLayout,
    view: FinancingRequestsView,
    permissions: [],
    modules: [],
    title: 'financingRequests',
    icon: PaperAirplaneIcon,
    showInNavigation: true,
  },
  {
    path: '/manage/financing-requests/:requestId',
    layout: ManagementLayout,
    view: FinancingRequestDetailView,
    permissions: [],
    modules: [],
    title: 'financingRequestDetails',
    showInNavigation: false,
  },
  {
    path: '/manage/company',
    layout: ManagementLayout,
    view: CompanyProfileView,
    permissions: [],
    modules: [],
    title: 'businessProfile',
    icon: BuildingOfficeIcon,
    showInNavigation: true,
  },
  {
    path: '/manage/profile',
    layout: ManagementLayout,
    view: ProfileView,
    permissions: [],
    modules: [],
    title: 'myProfile',
    icon: UserCircleIcon,
    showInNavigation: true,
  },
  // {
  //   path: '/manage/quotes/new',
  //   layout: ManagementLayout,
  //   view: QuoteNewView,
  //   permissions: [],
  //   modules: [],
  //   title: 'Neues Angebot',
  //   icon: UserCircleIcon,
  //   showInNavigation: true,
  // },
  // {
  //   path: '/manage/quotes/:quoteId',
  //   layout: ManagementLayout,
  //   view: QuoteDetailView,
  //   permissions: [],
  //   modules: [],
  //   title: 'Bestehendes Angebot',
  //   icon: UserCircleIcon,
  //   showInNavigation: true,
  // },

  // auth
  {
    path: '/auth/login',
    layout: AuthLayout,
    view: LoginView,
    permissions: [],
    modules: [],
  },
  {
    path: '/auth/register',
    layout: AuthLayout,
    view: RegisterView,
    permissions: [],
    modules: [],
  },
  {
    path: '/auth/reset-password',
    layout: AuthLayout,
    view: ResetPasswordView,
    permissions: [],
    modules: [],
  },
  {
    path: '/auth/forgot-password',
    layout: AuthLayout,
    view: ForgotPasswordView,
    permissions: [],
    modules: [],
  },
  {
    path: '/auth/verify-email',
    layout: AuthLayout,
    view: VerifyEmailView,
    permissions: [],
    modules: [],
  },

  // errors
  {
    path: '/manage/*',
    layout: ManagementLayout,
    view: Error404View,
    permissions: [],
    modules: [],
  },
  {
    path: '*',
    layout: AuthLayout,
    view: Error404View,
    permissions: [],
    modules: [],
  },
];

