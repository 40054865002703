/* eslint-disable default-param-last */
// import { UserRole } from "@das-zahlwerk/dz-models";

import actions from "../actions";
// import {
//   mergeByKey,
//   copy,
//   replaceByKey,
//   unshift,
//   removeFromArrayIfExists,
//   addToArrayIfNotExists,
// } from "../../helper";
// import { routes } from "../../routes";
// import { Permissions } from "../../services/auth/permissions";

// const assginedPermissions = {
//   [UserRole.REGISTRATED_USER]: [],
//   [UserRole.DZ_ADMIN]: [
//     Permissions.ROUTE_ADMIN,
//     Permissions.ROUTE_ADMIN_NAVIGATOR,
//     Permissions.CANVAS_EDIT_TEMPLATE,
//   ],
// };

interface IState {
  addedPermissions: string[];
  permissions: string[];
  businessPermissions: string[];
  roles: string[];
  modules: string[];
}

const initialState: IState = {
  addedPermissions: [],
  permissions: [],
  businessPermissions: [],
  roles: [],
  modules: [],
};

export default function permissions(
  state: IState = initialState,
  action: { type: string; payload: any }
): IState {
  const { type, payload } = action;
  switch (type) {
    case actions.SET_ROLES:
      // let pems: any[] = [];
      // payload.map((role) => {
      //   pems = [...pems, ...assginedPermissions[role]];
      // });

      return {
        ...state,
        roles: payload,
        // permissions: [...pems, ...state.addedPermissions],
      };
    case actions.SET_MODULES:
      return {
        ...state,
        modules: [...payload],
      };
    case actions.ADD_PERMISSIONS:
      return {
        ...state,
        addedPermissions: [...state.addedPermissions, ...payload],
        permissions: [...state.permissions, ...payload],
      };

    default:
      return state;
  }
}
