import React, { memo } from 'react';

export const AuthLayout = memo((props: { children: any }) => {
  return (
    <div className='min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8'>
      {props.children}
    </div>
  );
});

