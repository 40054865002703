import React, { useEffect, useState } from 'react';
import { AuthFeature } from '../../../components/features/auth-feature/auth-feature';
import { useDispatch, useSelector } from 'react-redux';
import actions from '../../../state/actions';
import { RootState } from '../../../state/store';
import { useQuery } from '../../../hooks/useQuery';
import { useNavigate } from 'react-router-dom';
import { CheckCircleIcon } from '@heroicons/react/24/solid';
import { Link, Spinner } from '@das-zahlwerk/dz-components';
import { useTranslation } from 'react-i18next';

export function VerifyEmailView() {
  const { t } = useTranslation();
  const { email, code } = useQuery();
  const { errors, loading, signedIn, emailVerified } = useSelector((s: RootState) => s.user);
  const history = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (loading.length === 0 && errors.length === 0 && emailVerified) {
      if (signedIn) {
        setTimeout(() => {
          history('/manage/dashboard');
        }, 1000);
      } else {
        setTimeout(() => {
          history('/auth/login');
        }, 1000);
      }
    }
  }, [emailVerified, loading, errors]);

  useEffect(() => {
    if (!code) {
      history('/auth/login');
    } else {
      dispatch({
        type: actions.POST_VERIFY_EMAIL_REQUEST,
        payload: {
          emailHash: email,
          token: code,
        },
      });
    }
  }, []);

  return (
    <>
      <AuthFeature title='E-Mail verifizieren'>
        <div className='flex flex-col items-center gap-4'>
          {loading.includes(actions.POST_VERIFY_EMAIL_REQUEST) && (
            <>
              <p>{t('globals.text.emailWillBeVerified')}</p>
              <Spinner color='DARK' size='MEDIUM' />
            </>
          )}
          {errors.includes(actions.POST_VERIFY_EMAIL_REQUEST) && (
            <>
              <p>{t('globals.text.emailVerifiedError')}</p>
              <Link href='/auth/login'>Erneut einloggen</Link>
            </>
          )}
          {!loading.includes(actions.POST_VERIFY_EMAIL_REQUEST) &&
            !errors.includes(actions.POST_VERIFY_EMAIL_REQUEST) && (
              <>
                <p>{t('globals.text.emailSuccessfullyVerified')}</p>
                {signedIn && <p>{t('globals.text.emailSuccessfullyVerifiedLoggedIn')}</p>}
              </>
            )}
        </div>
      </AuthFeature>
    </>
  );
}

