import { Fragment, memo, useEffect, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';

import authService from '../../services/auth/auth.service';
import actions from '../../state/actions';
import { RootState } from '../../state/store';
import { checkIfPathIsCurrent, classNames } from '../../helper';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { routes } from '../../routes';
import { useTranslation } from 'react-i18next';
import { DZNotyfProvider } from '../../components/providers/notyf/notyf.component';
import EMailVerifiedBanner from '../../components/banner/email-verified-banner';
import { Button } from '@das-zahlwerk/dz-components';
import { DZModal, MODAL_NAMES } from '../../components/modals/modals';

interface INavigationItem {
  name: string;
  href: string;
  icon: (props: React.SVGProps<SVGSVGElement>) => JSX.Element;
  current: boolean;
}
export const ManagementLayout = memo((props: { children: any }) => {
  const { t } = useTranslation();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const { user } = useSelector((s: RootState) => s.user);
  const { business, loading: businessLoading } = useSelector((s: RootState) => s.business);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigation: INavigationItem[] = routes
  .filter((r) => r.showInNavigation)
    .map((r) => {
      return {
        name: r.title!,
        href: r.path!,
        icon: r.icon!,
        current: checkIfPathIsCurrent(location.pathname, r.path),
      };
    });
  const activeNavigationItem = routes.find((r) => checkIfPathIsCurrent(location.pathname, r.path));

  const { emailVerified } = user || { emailVerified: true };
  useEffect(() => {
    if (!user) {
      authService
        .currentAuthenticatedUser()
        .then((u) => {
          dispatch({ type: actions.GET_USER_REQUEST });
          if (!business && !businessLoading.includes(actions.GET_BUSINESS_REQUEST)) {
            dispatch({ type: actions.GET_BUSINESS_REQUEST });
          }
        })
        .catch((e) => {});
    }
    return () => {};
  }, []);

  return (
    <>
      <div>
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog as='div' className='relative z-40 md:hidden' onClose={setSidebarOpen}>
            <Transition.Child
              as={Fragment}
              enter='transition-opacity ease-linear duration-300'
              enterFrom='opacity-0'
              enterTo='opacity-100'
              leave='transition-opacity ease-linear duration-300'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <div className='fixed inset-0 bg-gray-600 bg-opacity-75' />
            </Transition.Child>

            <div className='fixed inset-0 flex z-40'>
              <Transition.Child
                as={Fragment}
                enter='transition ease-in-out duration-300 transform'
                enterFrom='-translate-x-full'
                enterTo='translate-x-0'
                leave='transition ease-in-out duration-300 transform'
                leaveFrom='translate-x-0'
                leaveTo='-translate-x-full'
              >
                <Dialog.Panel className='relative flex-1 flex flex-col max-w-xs w-full bg-white'>
                  <Transition.Child
                    as={Fragment}
                    enter='ease-in-out duration-300'
                    enterFrom='opacity-0'
                    enterTo='opacity-100'
                    leave='ease-in-out duration-300'
                    leaveFrom='opacity-100'
                    leaveTo='opacity-0'
                  >
                    <div className='absolute top-0 right-0 -mr-12 pt-2'>
                      <button
                        type='button'
                        className='ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white'
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className='sr-only'>Close sidebar</span>
                        <XMarkIcon className='h-6 w-6 text-white' aria-hidden='true' />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className='flex-1 h-0 pt-5 pb-4 overflow-y-auto'>
                    <div className='flex-shrink-0 flex items-center px-4'>
                      <Link to='/manage'>
                        <img
                          className='h-8 w-auto'
                          src='https://uploads-ssl.webflow.com/61eae2476773465cc8963301/61f04df3f4857ed904ab82cd_logo.svg'
                          alt='Das Zahlwerk'
                        />
                      </Link>
                    </div>
                    <nav className='mt-5 px-2 space-y-1'>
                      <Button
                        className='block sm:hidden'
                        fullWidth
                        onClick={() => {
                          dispatch({
                            type: actions.OPEN_MODAL,
                            modal: MODAL_NAMES.FINANCING_REQUEST_ADD,
                          });
                          setSidebarOpen(false);
                        }}
                      >
                        Erstellen
                      </Button>
                      {navigation.map((item) => (
                        <Link
                          key={item.name}
                          to={item.href}
                          className={classNames(
                            item.current
                              ? 'bg-gray-100 text-gray-900'
                              : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                            'group flex items-center px-2 py-2 text-base font-medium rounded-md',
                          )}
                          onClick={() => setSidebarOpen(false)}
                        >
                          <item.icon
                            className={classNames(
                              item.current
                                ? 'text-gray-500'
                                : 'text-gray-400 group-hover:text-gray-500',
                              'mr-4 flex-shrink-0 h-6 w-6',
                            )}
                            aria-hidden='true'
                          />
                          {t(`globals.titles.${item.name}`)}
                        </Link>
                      ))}
                    </nav>
                  </div>
                  <div className='flex-shrink-0 flex border-t border-gray-200 p-4'>
                    <div className='flex items-center'>
                      <div className='ml-3'>
                        <p className='text-base font-medium text-gray-700 group-hover:text-gray-900'>
                          {[user?.firstName, user?.lastName].join(' ')}
                        </p>
                        <button
                          onClick={() => authService.signOut()}
                          className='flex-shrink-0 group block'
                        >
                          <p className='text-sm font-medium text-gray-500 group-hover:text-gray-700'>
                            {t('globals.buttons.logout')}
                          </p>
                        </button>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
              <div className='flex-shrink-0 w-14'>
                {/* Force sidebar to shrink to fit close icon */}
              </div>
            </div>
          </Dialog>
        </Transition.Root>
        {/* <DZSlideOver /> */}
        <DZModal />
        {/* Static sidebar for desktop */}
        <div className='hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0'>
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className='flex-1 flex flex-col min-h-0 border-r border-gray-200 bg-white'>
            <div className='flex-1 flex flex-col pt-5 pb-4 overflow-y-auto'>
              <div className='flex items-center flex-shrink-0 px-4'>
                <Link to='/manage'>
                  <img
                    className='h-8 w-auto'
                    src='https://uploads-ssl.webflow.com/61eae2476773465cc8963301/61f04df3f4857ed904ab82cd_logo.svg'
                    alt='Workflow'
                  />
                </Link>
              </div>
              <nav className='mt-5 flex-1 px-2 bg-white space-y-1'>
                {navigation.map((item) => (
                  <Link
                    key={item.name}
                    to={item.href}
                    className={classNames(
                      item.current
                        ? 'bg-gray-100 text-gray-900'
                        : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                      'group flex items-center px-2 py-2 text-sm font-medium rounded-md',
                    )}
                  >
                    <item.icon
                      className={classNames(
                        item.current ? 'text-gray-500' : 'text-gray-400 group-hover:text-gray-500',
                        'mr-3 flex-shrink-0 h-6 w-6',
                      )}
                      aria-hidden='true'
                    />
                    {t(`globals.titles.${item.name}`)}
                  </Link>
                ))}
              </nav>
            </div>
            <div className='flex-shrink-0 flex border-t border-gray-200 p-4'>
              <div className='flex items-center'>
                <div className='ml-3'>
                  <p className='text-sm font-medium text-gray-700 group-hover:text-gray-900'>
                    {[user?.firstName, user?.lastName].join(' ')}
                  </p>
                  <button
                    onClick={() => authService.signOut()}
                    className='flex-shrink-0 w-full group block'
                  >
                    <p className='text-xs font-medium text-gray-500 group-hover:text-gray-700 text-left'>
                      Logout
                    </p>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='md:pl-64 flex flex-col flex-1'>
          <div className='sticky top-0 z-10 md:hidden pl-1 pt-1 sm:pl-3 sm:pt-3 bg-gray-100'>
            <button
              type='button'
              className='-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-casal-500'
              onClick={() => setSidebarOpen(true)}
            >
              <span className='sr-only'>Open sidebar</span>
              <Bars3Icon className='h-6 w-6' aria-hidden='true' />
            </button>
          </div>
          <main className='flex-1'>
            <DZNotyfProvider />
            {!emailVerified && <EMailVerifiedBanner />}
            {/* Quick Actions Button */}
            <div className='hidden bg-white sm:flex sm:items-center sm:justify-between h-16 px-4 sm:px-6 md:px-8'>
              <div className='min-w-0 flex-1'></div>
              <div className='flex'>
                <Button
                  onClick={() => {
                    dispatch({
                      type: actions.OPEN_MODAL,
                      modal: MODAL_NAMES.FINANCING_REQUEST_ADD,
                    });
                  }}
                >
                  Erstellen
                </Button>
              </div>
            </div>
            <div className='py-6'>
              <div className='max-w-7xl mx-auto px-4 sm:px-6 md:px-8'>
                <h1 className='text-2xl font-semibold text-gray-900'>
                  {activeNavigationItem?.title
                    ? t(`globals.titles.${activeNavigationItem?.title}`)
                    : ''}
                </h1>
              </div>
              <div className='max-w-7xl mx-auto px-4 sm:px-6 md:px-8'>
                <div className='py-4 grid grid-cols-1 gap-5'>{props.children}</div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
});

