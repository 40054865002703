import { Business, LegalForm } from '@das-zahlwerk/dz-models';
import React, { useEffect } from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { InputField } from '@das-zahlwerk/dz-components';
import { CardHeading } from '@das-zahlwerk/dz-components';
import { GridContainer } from '@das-zahlwerk/dz-components';
import { CardActions } from '@das-zahlwerk/dz-components';
import { Button } from '@das-zahlwerk/dz-components';
import { useDispatch, useSelector } from 'react-redux';
import actions from '../../../state/actions';
import { SelectField } from '@das-zahlwerk/dz-components';
import { RootState } from '../../../state/store';
import { useTranslation } from 'react-i18next';
import { autoFormatDate, formatMobilePhone } from '../../../utils/data-helper';
import { CompanyProfileDataLogoForm } from './company-profile-form-logo';

interface IProps {
  business?: Business;
}

type FormData = {
  businessName: string;
  legalForm: LegalForm;
  firstName: string;
  lastName: string;
  birthDate: string;
  address: {
    street: string;
    streetNo: string;
    postalCode: string;
    city: string;
  };
  phoneNumber: string;
  contactEmail: string;
  businessLogoUrl?: string;
};

const addressSchema = yup
  .object()
  .shape({
    street: yup.string().required(),
    streetNo: yup.string().required(),
    postalCode: yup.string().required(),
    city: yup.string().required(),
  })
  .required('Adresse erforderlich');

const schema = yup
  .object()
  .shape({
    businessName: yup.string().required('Firmenname erforderlich'),
    legalForm: yup.string().required('Vorname erforderlich'),
    firstName: yup.string().required('Vorname erforderlich'),
    lastName: yup.string().required('Nachname erforderlich'),
    birthDate: yup
      .string()
      .required('Geburtsdatum erforderlich')
      .matches(/^(0[1-9]|[12][0-9]|3[01])[.](0[1-9]|1[012])[.](19|20)\d\d$/, {
        message: 'Bitte im Format TT.MM.JJJJ angeben',
      }),
    address: addressSchema,
  })
  .required();

export function CompanyProfileDataForm({ business }: IProps) {
  const { t } = useTranslation();
  const { loading } = useSelector((s: RootState) => s.business);
  const dispatch = useDispatch();
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors, isDirty },
    getValues,
    setValue,
  } = useForm<FormData>({
    // resolver: yupResolver(schema), // FIXME
    reValidateMode: 'onChange',
  });

  const onSubmit = handleSubmit(async (data) => {
    console.log('DATA', data);
    try {
      dispatch({
        type: actions.PATCH_BUSINESS_REQUEST,
        payload: data,
      });
    } catch (e: any) {
      console.log('ERROR', e);
    }
  });

  useEffect(() => {
    if (business != null) {
      const {
        businessName,
        legalForm,
        firstName,
        lastName,
        birthDate,
        address,
        phoneNumber,
        contactEmail,
        businessLogoUrl,
      } = business;
      reset({
        businessName,
        legalForm,
        firstName,
        lastName,
        birthDate,
        address,
        phoneNumber,
        contactEmail,
        businessLogoUrl,
      });
    }
  }, [business]);

  const businessLogoUrl = getValues('businessLogoUrl');
  return (
    <form className='flex flex-col divide-y space-y-6' onSubmit={onSubmit} noValidate>
      <GridContainer>
        <InputField
          register={register}
          label='Unternehmensname / öffentlicher Name (bei Einzelunternehmen)'
          type='text'
          name='businessName'
          autoComplete='businessName'
          errors={errors}
          className='col-span-6 sm:col-span-12'
        />
        <SelectField
          items={Object.keys(LegalForm).map((item) => {
            return {
              name: t(`enums.legalForm.${item}`),
              value: item,
            };
          })}
          label='Rechtsform'
          name='legalForm'
          errors={errors}
          className='col-span-6 sm:col-span-7'
          value={getValues('legalForm')}
          onChange={(value: LegalForm) => {
            setValue('legalForm', LegalForm[value], { shouldValidate: true, shouldDirty: true });
          }}
        />
        <InputField
          register={register}
          label='Vorname - Geschäftsführer / Inhaber'
          type='text'
          name='firstName'
          autoComplete='firstName'
          errors={errors}
          className='col-span-3 sm:col-span-6'
        />
        <InputField
          register={register}
          label='Nachname - Geschäftsführer / Inhaber'
          type='text'
          name='lastName'
          autoComplete='lastName'
          errors={errors}
          className='col-span-3 sm:col-span-6'
        />
        <InputField
          register={register}
          label='Geburtsdatum - Geschäftsführer / Inhaber'
          type='text'
          name='birthDate'
          autoComplete='birthDate'
          errors={errors}
          placeholder={'13.02.1980'}
          className='col-span-3 sm:col-span-6'
          onInput={(evt) => {
            evt.currentTarget.value = autoFormatDate(evt.currentTarget.value);
          }}
        />
        <div className='col-span-3 sm:col-span-6' />
        <InputField
          register={register}
          label='Adresse'
          type='text' // TODO Google autofill
          name='address.street'
          autoComplete='street'
          errors={errors}
          className='col-span-4 sm:col-span-5'
        />
        <InputField
          register={register}
          label='Hausnummer'
          type='text'
          name='address.streetNo'
          autoComplete='streetNo'
          errors={errors}
          className='col-span-2 sm:col-span-2'
        />
        <div className='hidden sm:block sm:col-span-5' />

        <InputField
          register={register}
          label='PLZ'
          type='text'
          name='address.postalCode'
          autoComplete='postalCode'
          errors={errors}
          className='col-span-2 sm:col-span-2'
        />
        <InputField
          register={register}
          label='Stadt'
          type='text'
          name='address.city'
          autoComplete='city'
          errors={errors}
          className='col-span-4 sm:col-span-5'
        />
        <div className='hidden sm:block sm:col-span-5' />
        {/* Logo Upload Section */}
        <div className='col-span-12'>
          <CompanyProfileDataLogoForm
            businessLogoUrl={businessLogoUrl}
            setLogoUrl={(logoUrl: string | undefined) => {
              console.log('LOGO URL', logoUrl);
              setValue('businessLogoUrl', logoUrl, {
                shouldValidate: true,
                shouldDirty: true,
                shouldTouch: true,
              });
            }}
          />
        </div>
      </GridContainer>
      <div>
        <CardHeading title='Firmenkontakt' subTitle='Kontakt damit Kunden Dich erreichen können.' />
        <GridContainer>
          <InputField
            register={register}
            label='Telefonnumer'
            type='text'
            name='phoneNumber'
            autoComplete='phoneNumber'
            errors={errors}
            className='col-span-6 sm:col-span-7'
            placeholder='171 1234567'
            leadingText='+49'
            inputClassName='pl-12'
            onInput={(evt) => {
              evt.currentTarget.value = formatMobilePhone(evt.currentTarget.value);
            }}
          />
          <InputField
            register={register}
            label='E-Mail-Adresse '
            type='text'
            name='contactEmail'
            autoComplete='contactEmail'
            errors={errors}
            className='col-span-6 sm:col-span-7'
          />
        </GridContainer>
      </div>
      <CardActions>
        <Button
          type='submit'
          disabled={!isDirty}
          loading={loading.includes(actions.PATCH_BUSINESS_REQUEST)}
        >
          Speichern
        </Button>
      </CardActions>
    </form>
  );
}

