import { TBadgeColor } from '@das-zahlwerk/dz-components';
import { RequestStatus } from '@das-zahlwerk/dz-models';

export function formatCurrency(
  t: (key: string | string[], options?: any) => React.ReactNode,
  value: number,
) {
  return t('numbers.currencyEUR', { val: value });
}

export function mapRequestStatusToColor(rs: RequestStatus = RequestStatus.DRAFT): TBadgeColor {
  switch (rs) {
    case RequestStatus.DRAFT:
      return 'primary';
    case RequestStatus.SENT:
      return 'warning';
    case RequestStatus.PRELIMINARY_APPLICATION_SUCCESSFUL:
      return 'success';
    case RequestStatus.LOAN_APPLICATION_SUCCESSFUL:
      return 'success';
    case RequestStatus.DONE:
      return 'success';
    case RequestStatus.CANCELLED:
      return 'danger';
    default:
      return 'primary';
  }
}

export function autoFormatDate(dateString: string) {
  try {
    var cleaned = ('' + dateString).replace(/\D/g, '');
    console.log('cleaned', cleaned);
    var match = cleaned.match(/^(\d{0,2})(\d{0,2})(\d{0,4})$/);
    return [
      match![1].length > 0 ? String(match![1]) : '',
      match![1].length === 2 ? '.' : '',
      match![2].length > 0 ? String(match![2]) : '',
      match![2].length === 2 ? '.' : '',
      match![3].length > 0 ? match![3] : '',
    ].join('');
  } catch (err) {
    return dateString;
  }
}
export function formatMobilePhone(dateString: string) {
  try {
    var cleaned = ('' + dateString).replace(/\D/g, '');
    console.log('cleaned', cleaned);
    var match = cleaned.match(/^0?(\d{3})(\d.*)$/);
    return [
      match![1].length > 0 ? String(match![1]) : '',
      match![1].length === 3 ? ' ' : '',
      match![2].length > 0 ? String(match![2]) : '',
    ].join('');
  } catch (err) {
    console.log(err);
    return dateString;
  }
}

