import React, { useState } from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { Button } from '@das-zahlwerk/dz-components';
import { InputField } from '@das-zahlwerk/dz-components';
import { AuthFeature } from '../../../components/features/auth-feature/auth-feature';
import authService from '../../../services/auth/auth.service';
import { useDispatch } from 'react-redux';
import actions from '../../../state/actions';
import { Link } from '@das-zahlwerk/dz-components';

type FormData = {
  email: string;
};
const schema = yup
  .object({
    email: yup.string().email('Keine gültige E-Mail').required('E-Mail erforderlich'),
  })
  .required();

export function ForgotPasswordView() {
  const [actionError, setActionError] = useState<string>();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<FormData>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    resolver: yupResolver(schema),
  });

  const onSubmit = handleSubmit(async (data) => {
    setLoading(true);
    try {
      await authService.forgotPassword(data.email);
      setLoading(false);
      reset();
      setActionError('Du solltest jetzt eine E-Mail erhalten haben!');
    } catch (e: any) {
      setLoading(false);
      const errorCode =
        e?.message?.replace('PreAuthentication failed with error ', '')?.replace('.', '') ||
        e?.code;
      console.log('errorCode', errorCode);
      setActionError(errorCode);
      dispatch({ type: actions.COGNITO_SIGN_OUT, payload: null });
    }
  });

  return (
    <>
      <AuthFeature title='Setze jetzt dein Passwort zurück'>
        <form className='space-y-6' onSubmit={onSubmit} noValidate>
          <InputField
            register={register}
            label='E-Mail'
            type='email'
            name='email'
            autoComplete='email'
            errors={errors}
          />

          <div>
            <Button type='submit' loading={loading} fullWidth>
              Abschicken
            </Button>
            {actionError && actionError.length > 0 && (
              <p className='mt-2 text-sm text-red-600'>{actionError}</p>
            )}
          </div>
          <div className='flex justify-end'>
            <Link href='/auth/login'>Zurück zum Login?</Link>
          </div>
        </form>
      </AuthFeature>
    </>
  );
}

