/* eslint-disable default-param-last */
import { User } from '@das-zahlwerk/dz-models';
import actions from '../actions';
import { addToArrayIfNotExists, removeFromArrayIfExists } from '../../helper';

interface IState {
  user?: User; // the logged in user
  cognitoUser: any;
  signedIn: boolean;
  errors: string[];
  loading: string[];
  emailVerified: boolean;
}

const initialState: IState = {
  user: undefined,
  cognitoUser: null,
  signedIn: false,
  errors: [],
  loading: [],
  emailVerified: false,
};

export default function user(
  state: IState = initialState,
  action: { type: string; payload: any },
): IState {
  const { type, payload } = action;
  console.log('USER REDUCER', type, payload, state);
  switch (type) {
    // AUTH
    case actions.COGNITO_SIGN_IN:
      return {
        ...state,
        cognitoUser: action.payload,
        signedIn: true,
      };

    case actions.COGNITO_SIGN_OUT:
      return {
        ...state,
        cognitoUser: null,
        signedIn: false,
        user: undefined,
      };

    // GET
    case actions.GET_USER_REQUEST:
      return {
        ...state,
        loading: [...addToArrayIfNotExists(state.loading, actions.GET_USER_REQUEST)],
        errors: [...removeFromArrayIfExists(state.errors, actions.GET_USER_REQUEST)],
      };
    case actions.GET_USER_SUCCESS:
      return {
        ...state,
        user: payload || state.user,
        loading: [...removeFromArrayIfExists(state.loading, actions.GET_USER_REQUEST)],
      };
    case actions.GET_USER_ERROR:
      return {
        ...state,
        loading: [...removeFromArrayIfExists(state.loading, actions.GET_USER_REQUEST)],
        errors: [...addToArrayIfNotExists(state.errors, actions.GET_USER_REQUEST)],
      };

    // PATCH
    case actions.PATCH_USER_REQUEST:
      return {
        ...state,
        loading: [...addToArrayIfNotExists(state.loading, actions.PATCH_USER_REQUEST)],
        errors: [...removeFromArrayIfExists(state.errors, actions.PATCH_USER_REQUEST)],
      };
    case actions.PATCH_USER_SUCCESS:
      return {
        ...state,
        user: payload || state.user,
        loading: [...removeFromArrayIfExists(state.loading, actions.PATCH_USER_REQUEST)],
      };
    case actions.PATCH_USER_ERROR:
      return {
        ...state,
        loading: [...removeFromArrayIfExists(state.loading, actions.PATCH_USER_REQUEST)],
        errors: [...addToArrayIfNotExists(state.errors, actions.PATCH_USER_REQUEST)],
      };

    // verify email
    case actions.POST_VERIFY_EMAIL_REQUEST:
      return {
        ...state,
        loading: [...addToArrayIfNotExists(state.loading, actions.POST_VERIFY_EMAIL_REQUEST)],
        errors: [...removeFromArrayIfExists(state.errors, actions.POST_VERIFY_EMAIL_REQUEST)],
      };
    case actions.POST_VERIFY_EMAIL_SUCCESS:
      return {
        ...state,
        emailVerified: true,
        loading: [...removeFromArrayIfExists(state.loading, actions.POST_VERIFY_EMAIL_REQUEST)],
      };
    case actions.POST_VERIFY_EMAIL_ERROR:
      return {
        ...state,
        emailVerified: false,
        loading: [...removeFromArrayIfExists(state.loading, actions.POST_VERIFY_EMAIL_REQUEST)],
        errors: [...addToArrayIfNotExists(state.errors, actions.POST_VERIFY_EMAIL_REQUEST)],
      };

    // verify email
    case actions.POST_RESEND_VERIFY_EMAIL_REQUEST:
      return {
        ...state,
        loading: [
          ...addToArrayIfNotExists(state.loading, actions.POST_RESEND_VERIFY_EMAIL_REQUEST),
        ],
        errors: [
          ...removeFromArrayIfExists(state.errors, actions.POST_RESEND_VERIFY_EMAIL_REQUEST),
        ],
      };
    case actions.POST_RESEND_VERIFY_EMAIL_SUCCESS:
      return {
        ...state,
        loading: [
          ...removeFromArrayIfExists(state.loading, actions.POST_RESEND_VERIFY_EMAIL_REQUEST),
        ],
      };
    case actions.POST_RESEND_VERIFY_EMAIL_ERROR:
      return {
        ...state,
        loading: [
          ...removeFromArrayIfExists(state.loading, actions.POST_RESEND_VERIFY_EMAIL_REQUEST),
        ],
        errors: [...addToArrayIfNotExists(state.errors, actions.POST_RESEND_VERIFY_EMAIL_REQUEST)],
      };

    default:
      return state;
  }
}

