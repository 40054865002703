import React, { memo } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { routes } from '../../routes';
export interface IRoute {
  path: string;
  permissions: any[];
  modules: any[];
  layout?: Function;
  view?: Function;
  redirect?: string;
  exact?: boolean;
  title?: string;
  icon?: (props: React.SVGProps<SVGSVGElement>) => JSX.Element;
  showInNavigation?: boolean;
}

export const DZUIRouting = memo(({ children }: { routes?: IRoute[]; children?: any }) => {
  return (
    <>
      <Routes>
        {routes.map((r, i) => {
          if (r.redirect) {
            return <Route key={i} {...r} element={<Navigate to={r.redirect} />}></Route>;
          } else {
            const Layout = r.layout!;
            const View = r.view!;

            return (
              <Route
                key={i}
                exact
                {...r}
                path={r.path}
                element={
                  <Layout {...r}>
                    <View {...r}></View>
                  </Layout>
                }
              ></Route>
            );
          }
        })}
      </Routes>
    </>
  );
});

