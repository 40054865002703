// import { IFinancingRequest } from '@das-zahlwerk/dz-models';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../state/store';
import actions from '../../../state/actions';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { classNames } from '../../../helper';

export function ErrorModal() {
  const { modalPayload } = useSelector((s: RootState) => s.ui);
  const dispatch = useDispatch();
  const { title, description } = modalPayload || {};

  useEffect(() => {
    setTimeout(() => {
      dispatch({ type: actions.CLOSE_MODAL });
    }, 3000);
  }, []);

  return (
    <div className='flex flex-col divide-y space-y-6'>
      <div>
        <div className='flex flex-col gap-2 items-center'>
          <XMarkIcon
            className={classNames('w-20 rounded-3xl p-1 border-0 text-red-600 bg-green-100')}
          />
          {title && <div className='text-lg font-medium text-black'>{title}</div>}
          {description && <div className='text-base font-medium text-gray-900'>{description}</div>}
        </div>
      </div>
    </div>
  );
}

