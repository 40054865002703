import { useEffect } from 'react';
import { Notyf } from 'notyf';

import 'notyf/notyf.min.css'; // for React and Vue
import './notyf.styles.css';
import { RootState } from '../../../state/store';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { NotificationType } from '@das-zahlwerk/dz-models';

const notyf = new Notyf({
  duration: 5000,
  dismissible: true,
  position: {
    x: 'right',
    y: 'top',
  },

  types: [
    {
      type: NotificationType.INFO,
      icon: {
        className: NotificationType.INFO,
      },
    },
    {
      type: NotificationType.ERROR,
      icon: {
        className: NotificationType.ERROR,
      },
    },
    {
      type: NotificationType.WARNING,
      icon: {
        className: NotificationType.WARNING,
      },
    },
    {
      type: NotificationType.SUCCESS,
      icon: {
        className: NotificationType.SUCCESS,
      },
    },
  ],
});

export const DZNotyfProvider = (props: any) => {
  const { t } = useTranslation();
  const { lastNotification } = useSelector((s: RootState) => s.notification);

  useEffect(() => {
    if (lastNotification) {
      notyf.open({
        type: lastNotification?.type,
        // message: lastNotification?.key,
        message: t('notifications.' + lastNotification?.key, { value: lastNotification?.value }),
      });
    }
  }, [lastNotification]);

  return props.children || null;
};

