import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import actions from '../../state/actions';
import { RootState } from '../../state/store';
import { AddFinancingRequestForm } from '../forms/add-financing-request-form/add-financing-request-form';
import { SendFinancingRequestPreview } from '../financing-request-preview/financing-request-preview';
import { classNames } from '../../helper';
import { SuccessModal } from './success-modal/success-modal';
import { ErrorModal } from './error-modal/error-modal';

export enum MODAL_NAMES {
  FINANCING_REQUEST_ADD = 'FINANCING_REQUEST_ADD',
  FINANCING_REQUEST_SENDPREV = 'FINANCING_REQUEST_SENDPREV',
  SUCCESS_MODAL = 'SUCCESS_MODAL',
  ERROR_MODAL = 'ERROR_MODAL',
}

const modals: {
  [key: string]: {
    title: string;
    component: React.FC<any>;
    settings?: {
      width: 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl' | '4xl' | '5xl';
    };
  };
} = {
  [MODAL_NAMES.FINANCING_REQUEST_ADD]: {
    title: 'addFinancingRequest',
    component: AddFinancingRequestForm,
  },
  [MODAL_NAMES.FINANCING_REQUEST_SENDPREV]: {
    title: 'financingRequestPreview',
    component: SendFinancingRequestPreview,
    settings: {
      width: '2xl',
    },
  },
  [MODAL_NAMES.SUCCESS_MODAL]: {
    title: 'successModal',
    component: SuccessModal,
  },
  [MODAL_NAMES.ERROR_MODAL]: {
    title: 'errorModal',
    component: ErrorModal,
  },
};

export const DZModal = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isModalOpen, modalName } = useSelector((s: RootState) => s.ui);

  function closeModal() {
    setTimeout(() => {
      dispatch({ type: actions.CLOSE_MODAL });
    }, 50);
  }

  const modalDetails = modalName && modals[modalName];
  let Component = modalDetails?.component;
  let { width } = modalDetails?.settings || { width: 'sm' };
  return (
    <Transition.Root show={isModalOpen} as={Fragment}>
      <Dialog as='div' className='relative z-10' onClose={() => {}}>
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
        </Transition.Child>

        <div className='fixed inset-0 z-10 overflow-y-auto'>
          <div className='flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              enterTo='opacity-100 translate-y-0 sm:scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 translate-y-0 sm:scale-100'
              leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            >
              <Dialog.Panel
                className={classNames(
                  'relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:p-6',

                  width === 'sm' && 'sm:max-w-sm',
                  width === 'md' && 'sm:max-w-md',
                  width === 'lg' && 'sm:max-w-lg',
                  width === 'xl' && 'sm:max-w-xl',
                  width === '2xl' && 'sm:max-w-2xl',
                  width === '3xl' && 'sm:max-w-3xl',
                  width === '4xl' && 'sm:max-w-4xl',
                  width === '5xl' && 'sm:max-w-5xl',
                  !width && 'sm:max-w-sm',
                )}
              >
                <div className='absolute top-0 right-0 hidden pt-4 pr-4 sm:block'>
                  <button
                    type='button'
                    className='rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-casal-500 focus:ring-offset-2'
                    onClick={() => closeModal()}
                  >
                    <span className='sr-only'>Close</span>
                    <XMarkIcon className='h-6 w-6' aria-hidden='true' />
                  </button>
                </div>
                <div>
                  <div className='mt-3 text-center sm:mt-5'>
                    {modalDetails?.title && (
                      <Dialog.Title className='text-lg font-medium text-gray-900'>
                        {t(`globals.titles.${modalDetails.title}`)}
                      </Dialog.Title>
                    )}
                    <div className='mt-2'>{Component && <Component />}</div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
        {/* <div className='fixed inset-0' />

        <div className='fixed inset-0 overflow-hidden'>
          <div className='absolute inset-0 overflow-hidden'>
            <div className='pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10'>
              <Transition.Child
                as={Fragment}
                enter='transform transition ease-in-out duration-500 sm:duration-700'
                enterFrom='translate-x-full'
                enterTo='translate-x-0'
                leave='transform transition ease-in-out duration-500 sm:duration-700'
                leaveFrom='translate-x-0'
                leaveTo='translate-x-full'
              >
                <Dialog.Panel className='pointer-events-auto w-screen max-w-md'>
                  <div className='flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl'>
                    <div className='px-4 sm:px-6'>
                      <div className='flex items-start justify-between'>
                        {modalDetails?.title && (
                          <Dialog.Title className='text-lg font-medium text-gray-900'>
                            {t(`globals.titles.${modalDetails.title}`)}
                          </Dialog.Title>
                        )}
                        <div className='ml-3 flex h-7 items-center'>
                          <button
                            type='button'
                            className='rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-casal-500 focus:ring-offset-2'
                            onClick={closeModal}
                          >
                            <span className='sr-only'>Close panel</span>
                            <XMarkIcon className='h-6 w-6' aria-hidden='true' />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className='relative mt-6 flex-1 px-4 sm:px-6'>
                      <div className='absolute inset-0 px-4 sm:px-6'>
                        {Component && <Component />}
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div> */}
      </Dialog>
    </Transition.Root>
  );
};

