import React, { useEffect } from 'react';
import { Badge, Card, Table } from '@das-zahlwerk/dz-components';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../state/store';
import { useTranslation } from 'react-i18next';
import { FinancingClient, FinancingRequest } from '@das-zahlwerk/dz-models';
import { formatCurrency, mapRequestStatusToColor } from '../../../utils/data-helper';
import actions from '../../../state/actions';
import EmptyState from '../../empty-state/empty-state';
import { PlusIcon } from '@heroicons/react/20/solid';
import { useNavigate } from 'react-router-dom';

interface IProps {}

export function FinancingRequestsTile({}: IProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { financingRequests, loading } = useSelector((s: RootState) => s.financingRequest);

  const dispatch = useDispatch();
  useEffect(() => {
    if (!loading.includes(actions.LIST_FINANCINGREQ_REQUEST)) {
      dispatch({
        type: actions.LIST_FINANCINGREQ_REQUEST,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Card title='Finanzierungsanfragen' fullWidth>
      <Table<FinancingRequest>
        items={financingRequests}
        loading={loading.includes(actions.LIST_FINANCINGREQ_REQUEST)}
        columnDefinition={[
          {
            type: 'data',
            label: 'Vorgangsbezeichnung',
            attributeName: 'requestName',
          },
          {
            type: 'data',
            label: 'Kunde',
            attributeName: ['clientDetails'],
            itemClassName: '',
            className: '',
            transform: ([clientDetails]: FinancingClient[]) => {
              console.log(clientDetails);
              if (clientDetails) {
                return `${clientDetails.firstName} ${clientDetails.lastName}`;
              }
              return '';
            },
          },
          {
            type: 'data',
            label: 'Status',
            attributeName: 'requestStatus',
            transform: (value) => {
              return (
                <Badge color={mapRequestStatusToColor(value)}>
                  {t(`enums.requestStatus.${value}`)}
                </Badge>
              );
            },
            // itemClassName: 'text-left',
            // className: 'text-left',
          },
          
          {
            type: 'data',
            label: 'Finanzierungsvolumen',
            attributeName: 'financeAmount',
            itemClassName: 'text-right hidden md:block',
            className: 'text-right hidden md:block',
            transform: (value) => formatCurrency(t, value),
          },
        ]}
        keyAttribute='requestId'
        rowAction={{
          attributeName: ['requestId'],
          onClick: (requestId: string) => {
            navigate(`/manage/financing-requests/${requestId}`);
          },
        }}
      />
      {financingRequests.length === 0 && loading.length === 0 && (
        <EmptyState
          description='emptyFinancingRequests'
          title='noFinancingRequests'
          ctaIcon={<PlusIcon />}
        />
      )}
    </Card>
  );
}

