import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import translations_de from './locales/de/translations';

i18next
  .use(initReactI18next)
  // .use(LanguageDetector)
  .init({
    resources: {
      de: {
        translations: translations_de,
      },
    },
    fallbackLng: 'de',
    debug: true,

    // objectNotation: true,

    // have a common namespace used around the full app
    ns: ['translations', 'modules'],
    defaultNS: 'translations',

    interpolation: {
      escapeValue: false, // not needed for react!!
    },

    react: {
      wait: true,
    },
  } as any);

export default i18next;

