import axios, { AxiosInstance } from 'axios';

export async function uploadToS3({
  apiPath,
  axiosInstance,
  fileType,
  fileContents,
}: {
  fileType: string;
  fileContents: File;
  apiPath: string;
  axiosInstance: AxiosInstance;
}) {
  const presignedPostUrl = await getPresignedPostUrl(
    apiPath,
    axiosInstance,
    fileType,
    fileContents.name,
  );

  const formData = new FormData();
  formData.append('Content-Type', fileType);
  Object.entries(presignedPostUrl.fields).forEach(([k, v]) => {
    formData.append(k, v);
  });
  formData.append('file', fileContents); // The file has be the last element
  try {
    const response = await axios.post(presignedPostUrl.url, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    console.log('S3 response', response);
  } catch (error: any) {
    console.log('S3 response', error.response?.data);
  }
  return {
    fullPath: [presignedPostUrl.url, presignedPostUrl.fields.key].join('/'),
    cleanedFileName: presignedPostUrl.fields?.cleanedFileName,
  } 
}

type PresignedPostUrlResponse = {
  url: string;
  fields: {
    key: string;
    acl: string;
    bucket: string;
    cleanedFileName?: string;
  };
};

async function getPresignedPostUrl(
  apiPath: string,
  axiosInstance: AxiosInstance,
  fileType: string,
  fileName: string,
) {
  const resp = await axiosInstance.post(apiPath, {
    fileType,
    fileName,
  });

  return resp?.data as PresignedPostUrlResponse;
}

