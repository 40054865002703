import { NotificationType, Notification } from '@das-zahlwerk/dz-models';
import { call, put, takeLatest } from 'redux-saga/effects';
import actions from '../actions';
import axiosService from '../../services/axios/axios.service';
import { formatUrl } from '../../helper';

const ENV = process.env;

function getDefaultUrl(a: actions, props: { [key: string]: string } = {}): string {
  console.log('PPPROPS', props);
  const { requestId = null } = props;
  if (
    a === actions.GET_FINANCINGREQ_REQUEST ||
    a === actions.PATCH_FINANCINGREQ_REQUEST ||
    a === actions.DELETE_FINANCINGREQ_REQUEST
  ) {
    return `/business/financingRequests/${requestId}`;
  }
  if (
    a === actions.POST_SENDFINANCINGREQ_REQUEST
  ) {
    return `/business/financingRequests/${requestId}/send`;
  }
  if (
    a === actions.GET_FINANCINGREQPREV_REQUEST
  ) {
    return `/business/financingRequests/${requestId}/preview`;
  }
  if (a === actions.LIST_FINANCINGREQ_REQUEST || a === actions.POST_FINANCINGREQ_REQUEST) {
    return `/business/financingRequests`;
  }
  console.log(`Can't find action in default saga`);
  return '';
}

function* defaultRequest(action: { type: actions; payload: any; query: any }) {
  const { type, payload, query } = action;
  console.log('action', action);
  try {
    const data: { data: any; status: number } = yield call(axiosService.FINANCINGREQ as any, {
      method: `${type.startsWith('LIST') ? 'get' : type.split('_')[0].toLocaleLowerCase()}`,
      url: formatUrl({
        baseUrl: ENV.API_FINANCINGREQ_URL!,
        path: getDefaultUrl(type, payload),
        queryParams: query,
      }),
      data: payload || null,
    });
    if (data?.status === 200) {
      yield put({
        type: type.replace('REQUEST', 'SUCCESS'),
        payload: data?.data?.items || data?.data,
      });
    }
  } catch (e: any) {
    yield put({ type: type.replace('REQUEST', 'ERROR'), payload: e.message });
    if (e?.response?.data?.code) {
      yield put({
        type: actions.SEND_NOTIFICATION,
        payload: new Notification({
          type: NotificationType.ERROR,
          key: e.response.data?.message || e.response.data.code,
        }),
      });
    }
  }
}

const sagas = [
  takeLatest(actions.GET_FINANCINGREQ_REQUEST, defaultRequest),
  takeLatest(actions.POST_FINANCINGREQ_REQUEST, defaultRequest),
  takeLatest(actions.LIST_FINANCINGREQ_REQUEST, defaultRequest),
  takeLatest(actions.DELETE_FINANCINGREQ_REQUEST, defaultRequest),
  takeLatest(actions.PATCH_FINANCINGREQ_REQUEST, defaultRequest),
  takeLatest(actions.POST_SENDFINANCINGREQ_REQUEST, defaultRequest),
  takeLatest(actions.GET_FINANCINGREQPREV_REQUEST, defaultRequest),
];

export default sagas;

