/* eslint-disable default-param-last */
import { MODAL_NAMES } from '../../components/modals/modals';
import { SLIDE_OVER_NAMES } from '../../components/slide-overs/slide-over';
import actions from '../actions';

interface IState {
  globalLoading: boolean;
  isSlideOverOpen: boolean;
  slideOverName?: SLIDE_OVER_NAMES;
  isModalOpen: boolean;
  modalName?: MODAL_NAMES;
  modalPayload?: any
}

const initialState: IState = {
  globalLoading: true,
  isSlideOverOpen: false,
  slideOverName: undefined,
  isModalOpen: false,
  modalName: undefined,
  modalPayload: undefined,
};

export default function ui(
  state: IState = initialState,
  action: { type: any; payload: any; modal?: MODAL_NAMES },
): IState {
  const { payload = null, modal = undefined } = action;
  switch (action.type) {
    case actions.ACTIVATE_GLOBAL_LOADING:
      return {
        ...state,
        globalLoading: true,
      };
    case actions.DEACTIVATE_GLOBAL_LOADING:
      return {
        ...state,
        globalLoading: false,
      };
    case actions.OPEN_SLIDE_OVER:
      return {
        ...state,
        isSlideOverOpen: true,
        slideOverName: payload
      };
    case actions.CLOSE_SLIDE_OVER:
      return {
        ...state,
        isSlideOverOpen: false,
        slideOverName: undefined
      };
    case actions.OPEN_MODAL:
      return {
        ...state,
        isModalOpen: true,
        modalName: modal,
        modalPayload: payload,
      };
    case actions.CLOSE_MODAL:
      return {
        ...state,
        isModalOpen: false,
        modalName: undefined,
        modalPayload: undefined
      };

    default:
      return state;
  }
}

