// import { IFinancingRequest } from '@das-zahlwerk/dz-models';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../state/store';
import { Button, Spinner } from '@das-zahlwerk/dz-components';
import actions from '../../state/actions';
import { RequestStatus } from '@das-zahlwerk/dz-models';
import { MODAL_NAMES } from '../modals/modals';
import { usePrevious } from '../../hooks/usePrevious';

export function SendFinancingRequestPreview() {
  const { financingRequests, loading, financingRequestPreview } = useSelector(
    (s: RootState) => s.financingRequest,
  );
  const { modalPayload } = useSelector((s: RootState) => s.ui);
  const dispatch = useDispatch();
  const { requestId } = modalPayload;

  useEffect(() => {
    dispatch({
      type: actions.GET_FINANCINGREQPREV_REQUEST,
      payload: {
        requestId,
      },
    });
  }, [requestId]);

  const financingRequest = financingRequests.find((fr) => fr.requestId === requestId);
  const { requestStatus, sentAt } = financingRequest!;
  const prevRequestStatus = usePrevious(requestStatus);

  useEffect(() => {
    console.log(prevRequestStatus, requestStatus);
    if (prevRequestStatus === RequestStatus.DRAFT && requestStatus === RequestStatus.SENT) {
      dispatch({
        type: actions.OPEN_MODAL,
        modal: MODAL_NAMES.SUCCESS_MODAL,
        payload: {
          title: 'Deine E-Mail wurde erfolgreich versandt.',
          description: 'Dein Kunde sollte in Kürze die E-Mail erhalten.',
        },
      });
    }
  }, [requestStatus]);

  return (
    <div className='flex flex-col divide-y space-y-6'>
      <div>
        <div className='flex flex-col space-y-4 text-left'>
          <table>
            <tbody>
              <tr>
                <td className='pr-2'>An:</td>
                <td>
                  {loading.includes(actions.GET_FINANCINGREQPREV_REQUEST)
                    ? ''
                    : financingRequest?.clientDetails?.email}
                </td>
              </tr>
              <tr>
                <td className='pr-2'>Betreff:</td>
                <td>
                  {loading.includes(actions.GET_FINANCINGREQPREV_REQUEST)
                    ? ''
                    : financingRequestPreview?.subject}
                </td>
              </tr>
            </tbody>
          </table>
          <div className='overflow-x-scroll 10vh max-h-96'>
            {loading.includes(actions.GET_FINANCINGREQPREV_REQUEST) ? (
              <Spinner size='LARGE' color='DARK' />
            ) : (
              <div dangerouslySetInnerHTML={{ __html: financingRequestPreview?.email ?? '' }} />
            )}
          </div>
        </div>
      </div>
      <div className='flex justify-between pt-6'>
        <Button
          color='secondary'
          onClick={() => {
            dispatch({
              type: actions.CLOSE_MODAL,
            });
          }}
        >
          Zurück
        </Button>
        <Button
          loading={loading.includes(actions.POST_SENDFINANCINGREQ_REQUEST)}
          onClick={() => {
            dispatch({
              type: actions.POST_SENDFINANCINGREQ_REQUEST,
              payload: {
                requestId: requestId,
              },
              query: {
                type: 'SMS_EMAIL',
              },
            });
          }}
        >
          Jetzt senden
        </Button>
      </div>
    </div>
  );
}

