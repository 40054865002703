import React from 'react';

interface IProps {
  title: string;
  subTitle?: JSX.Element;
  children: React.ReactNode;
}

export function AuthFeature({ title, subTitle, children }: IProps) {
  return (
    <>
      <div className='sm:mx-auto sm:w-full sm:max-w-md'>
        <img
          className='mx-auto h-12 w-auto'
          src='https://uploads-ssl.webflow.com/61eae2476773465cc8963301/61f04df3f4857ed904ab82cd_logo.svg'
          alt='Zahlwerk'
        />
        <h2 className='mt-6 text-center text-3xl font-extrabold text-gray-900'>{title}</h2>
        {subTitle && <p className='mt-2 text-center text-sm text-gray-600'>{subTitle}</p>}
      </div>

      <div className='mt-8 sm:mx-auto sm:w-full sm:max-w-md'>
        <div className='bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10'>{children}</div>
      </div>
    </>
  );
}

