import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import actions from '../../state/actions';

export default function EMailVerifiedBanner() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  function resendMail() {
    dispatch({
      type: actions.POST_RESEND_VERIFY_EMAIL_REQUEST,
    });
  }

  return (
    <div className='border-l-4 border-yellow-400 bg-yellow-50 p-4'>
      <div className='flex'>
        <div className='flex-shrink-0'>
          <ExclamationTriangleIcon className='h-5 w-5 text-yellow-400' aria-hidden='true' />
        </div>
        <div className='ml-3'>
          <p className='text-sm text-yellow-700'>
            {t('globals.text.emailNotVerifiedWarning')}{' '}
            <button
              onClick={resendMail}
              className='font-medium text-yellow-700 underline hover:text-yellow-600'
            >
              {t('globals.buttons.resendMail')}
            </button>
          </p>
        </div>
      </div>
    </div>
  );
}

