/**
 * Deep copy of object or array
 * @param {Object} [obj]
 * @returns {Object}
 */
export function copy(obj: any) {
  return JSON.parse(JSON.stringify(obj));
}

/**
 * Replace item in array with the new object
 * @param {string} [key] key from item in array and object
 * @param {*[]} [arr] array with all items
 * @param {Object} [obj] item which should be replaced in array
 * @returns {*[]} array with new object
 */
export function replaceByKey(key: string, arr: any[], obj: any) {
  const copiedArr = copy(arr);
  const index = copiedArr.findIndex((a: any) => obj[key] === a[key]);

  if (index > -1) {
    copiedArr[index] = obj;
  }

  return copiedArr;
}

function fallbackCopyTextToClipboard(text: string) {
  var textArea = document.createElement('textarea');
  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.top = '0';
  textArea.style.left = '0';
  textArea.style.position = 'fixed';

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    var successful = document.execCommand('copy');
    var msg = successful ? 'successful' : 'unsuccessful';
    console.log('Fallback: Copying text command was ' + msg);
  } catch (err) {
    console.error('Fallback: Oops, unable to copy', err);
  }

  document.body.removeChild(textArea);
}
export async function copyTextToClipboard(text: string) {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text);
    return;
  }
  return navigator.clipboard.writeText(text);
}

/**
 * Merges two or more arrays of objects into a single array by merging objects with the same key
 * (property).
 * @param {string} [key]
 * @param {...*[]} [arrays]
 * @returns {*[]}
 */
// export function mergeByKey(key: string, arr1: any[] = [], arr2: any[] = [], ...arr3: any[]) {
//   const array = [];
//   const groups = new Map(); // key => [pos in array, [array, of, objects, with, the, same, key]]

//   for (let i = 1; i < arguments.length; ++i) {
//     for (let j = 0; j < arguments[i].length; ++j) {
//       const element = arguments[i][j];
//       if (element) {
//         if (element.hasOwnProperty(key)) {
//           const keyValue = element[key];
//           if (groups.has(keyValue)) {
//             groups.get(keyValue)[1].push(element);
//           } else {
//             array.push(element);
//             groups.set(keyValue, [array.length - 1, []]);
//           }
//         } else {
//           array.push(element);
//         }
//       }
//     }
//   }
//   // @ts-ignore
//   for (let group of groups) {
//     if (group[1][1].length === 0) continue;
//     array[group[1][0]] = Object.assign.apply(Object, [{}, array[group[1][0]]].concat(group[1][1]) as any);
//   }

//   return array;
// }

export function mergeByKey(key: string, a1: any[], a2: any[]) {
  // merge a1 and a2 and remove dubplicate
  let merged = [...a1, ...a2];
  merged = merged.filter((v, i, a) => a.findIndex((t) => t[key] === v[key]) === i);

  const array: any = [];

  // make sure merge overwrtie the same key
  merged.forEach((item: any) => {
    const found = a2.find((item2) => item[key] === item2[key]);

    if (found) {
      array.push({ ...item, ...found });
    } else {
      array.push(item);
    }
  });

  return array;
}

/**
 * place item on top of array (one liner)
 * (property).
 * @param {*[]} [arr]
 * @param {Object} [obj]
 * @returns {*[]}
 */
export function unshift(arr: any[], obj: Object) {
  const arrCopy = copy(arr);
  arrCopy.unshift(obj);
  return arrCopy;
}

/**
 * place item on bottom of array (one liner)
 * (property).
 * @param {*[]} [arr]
 * @param {Object} [obj]
 * @returns {*[]}
 */
export function push(arr: any[], obj: Object) {
  const arrCopy = copy(arr);
  arrCopy.push(obj);
  return arrCopy;
}

/**
 * add item to array if not exists (no objects)
 * (property).
 * @param {*[]} [arr]
 * @param {string | number | boolea} [obj]
 * @returns {*[]}
 */
export function addToArrayIfNotExists(arr: any[], value: string | number | boolean) {
  if (arr.indexOf(value) === -1) {
    arr.push(value);
  }
  return arr;
}

/**
 * remove item to array if exists (no objects)
 * (property).
 * @param {*[]} [arr]
 * @param {string | number | boolea} [obj]
 * @returns {*[]}
 */
export function removeFromArrayIfExists(arr: any[], value: string | number | boolean) {
  return arr.filter((e) => e !== value);
}

/**
 * removes items from dom by class name
 * (property).
 * @param {string} [className]
 */
export function removeElementsByClass(className: string) {
  const elements = (document as any).getElementsByClassName(className);
  while (elements.length > 0) {
    elements[0].parentNode.removeChild(elements[0]);
  }
}
/**
 * combines multiple classnames to big string
 * (property).
 * @param {string[]} [classes]
 */
export function classNames(...classes: (string | boolean | undefined)[]) {
  return classes.filter((i) => i && i != null).join(' ');
}

export function getRandomInt(min: number, max: number) {
  const minVal = Math.ceil(min);
  const maxVal = Math.floor(max);
  // @ts-ignore
  return parseInt(Math.floor(Math.random() * (maxVal - minVal + 1) + minVal), 10);
}

// @ts-ignore
export function getProp(obj, level, ...rest) {
  if (obj == null || obj === undefined) return undefined;
  // eslint-disable-next-line no-prototype-builtins
  if (rest.length === 0 && obj.hasOwnProperty(level)) return obj[level];

  // @ts-ignore
  return getProp(obj[level], ...rest);
}

export function randomInteger(min: number, max: number) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function formatUrl({
  baseUrl,
  path,
  queryParams,
}: {
  baseUrl: string;
  path: string;
  queryParams?: { [key: string]: string | number | undefined };
}): string {
  let url = [baseUrl, path].join('');
  if (queryParams && Object.keys(queryParams).length > 0) {
    const queries: string[] = [];
    for (let index = 0; index < Object.keys(queryParams).length; index += 1) {
      const queryKey = Object.keys(queryParams)[index];
      const queryVal = queryParams[queryKey];
      if (queryVal != null) {
        queries.push([queryKey, queryVal].join('='));
      } else {
        queries.push(queryKey);
      }
    }
    const queryPath = queries.join('&');
    if (queryPath.length > 0) {
      url = [url, queryPath].join('?');
    }
  }
  return url;
}

export function checkIfPathIsCurrent(locationPathName: string, pathToCompare: string): boolean {
  if (pathToCompare.includes(':')) {
    let pathRegex = pathToCompare.replace(/\/(:[^\/]*)/g, '/.*');
    pathRegex = ['^', pathRegex, '$'].join('');
  
    const result = locationPathName.match(pathRegex);
    return result != null && result?.length > 0;
  } 
  return locationPathName === pathToCompare;
  
}
