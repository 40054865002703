import { NotificationType, Notification } from "@das-zahlwerk/dz-models";
import { call, put, takeLatest } from "redux-saga/effects";
import actions from "../actions";
import axiosService from "../../services/axios/axios.service";
import { formatUrl } from "../../helper";

const ENV = process.env;

function getDefaultUrl(a: actions, props: { [key: string]: string } = {}): string {
  const { quoteId = null } = props;
  if (
    a === actions.GET_CLIENT_REQUEST ||
    a === actions.PATCH_CLIENT_REQUEST ||
    a === actions.DELETE_CLIENT_REQUEST
  ) {
    return `/business/quotes/${quoteId}`;
  }
  if (a === actions.LIST_CLIENT_REQUEST || a === actions.POST_CLIENT_REQUEST) {
    return `/business/quotes`;
  }
  console.log(`Can't find action in default saga`);
  return "";
}

function* defaultRequest(action: { type: actions; payload: any; query: any }) {
  const { type, payload, query } = action;
  try {
    const data: { data: any; status: number } = yield call(axiosService.BUSINESS as any, {
      method: `${type.startsWith("LIST") ? "get" : type.split("_")[0].toLocaleLowerCase()}`,
      url: formatUrl({
        baseUrl: ENV.API_CLIENT_URL!,
        path: getDefaultUrl(type, payload),
        queryParams: query,
      }),
      data: payload || null,
    });
    if (data?.status === 200) {
      yield put({
        type: type.replace("REQUEST", "SUCCESS"),
        payload: data?.data?.items || data?.data,
      });
    }
  } catch (e: any) {
    yield put({ type: type.replace("REQUEST", "ERROR"), payload: e.message });
    if (e?.response?.data?.code) {
      yield put({
        type: actions.SEND_NOTIFICATION,
        payload: new Notification({
          type: NotificationType.ERROR,
          key: e.response.data?.message || e.response.data.code,
        }),
      });
    }
  }
}

const sagas =  [
  takeLatest(actions.GET_CLIENT_REQUEST, defaultRequest),
  takeLatest(actions.POST_CLIENT_REQUEST, defaultRequest),
  takeLatest(actions.LIST_CLIENT_REQUEST, defaultRequest),
  takeLatest(actions.DELETE_CLIENT_REQUEST, defaultRequest),
  takeLatest(actions.PATCH_CLIENT_REQUEST, defaultRequest),
];
export default sagas;
