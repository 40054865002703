import React from 'react';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';

// import { ErrorBoundary } from './components/providers/error-boundary/error-boundary.component';
import i18n from './services/i18n/i18n';
import { store } from './state/store';

export const Providers = ({ children }: any) => {
  return (
    <Provider store={store}>
      <I18nextProvider i18n={i18n}>{children}</I18nextProvider>
    </Provider>
  );
};

