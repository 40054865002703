import { useLocation } from "react-router";
import { parse } from 'qs';

export function useQuery() {
  const location = useLocation();
  const queries = parse(location.search.replace('?', ''))

  return {
    ...queries
  };
}