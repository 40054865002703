// import { Card } from '@das-zahlwerk/dz-components';
import React from 'react';
// import { AnnouncementsTile } from '../../../components/tiles/announcements-tile/announcements-tile';
import { FinancingRequestsTile } from '../../../components/tiles/financing-requests-tile/financing-requests-tile';
import { WelcomeBackTile } from '../../../components/tiles/welcome-back-tile/welcome-back-tile';

export function DashboardView() {
  return (
    <div className='grid grid-cols-1 gap-4 items-start lg:gap-8'>
      {/* Left column */}
      <div className='grid grid-cols-1 gap-4'>
        <WelcomeBackTile />
        <FinancingRequestsTile />
      </div>
      {/* Right column */}
      <div className='grid grid-cols-1 gap-4'>
        {/* Announcements */}
        {/* <AnnouncementsTile /> */}
        {/* <Card 
          title='Funktionen vorschlagen'
        >
          <div className='h-[850px]'>
            HIER NEUES FORM VON FELIX
          </div>
        </Card> */}
      </div>
    </div>
  );
}

