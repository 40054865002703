/* eslint-disable default-param-last */
import { FinancingRequest } from '@das-zahlwerk/dz-models';
import actions from '../actions';
import { mergeByKey, removeFromArrayIfExists, addToArrayIfNotExists } from '../../helper';

interface IState {
  financingRequests: FinancingRequest[];
  financingRequestPreview?: { email: string; subject: string };
  errors: string[];
  loading: string[];
}

const initialState: IState = {
  financingRequests: [],
  financingRequestPreview: undefined,
  errors: [],
  loading: [],
};

export default function financingRequest(
  state: IState = initialState,
  action: { type: string; payload: any },
): IState {
  const { type, payload } = action;
  switch (type) {
    // GET
    case actions.GET_FINANCINGREQ_REQUEST:
      return {
        ...state,
        loading: [...addToArrayIfNotExists(state.loading, actions.GET_FINANCINGREQ_REQUEST)],
        errors: [...removeFromArrayIfExists(state.errors, actions.GET_FINANCINGREQ_REQUEST)],
      };
    case actions.GET_FINANCINGREQ_SUCCESS:
      return {
        ...state,
        financingRequests: [
          ...mergeByKey('resourceId', state.financingRequests, [payload]).sort(
            (a: FinancingRequest, b: FinancingRequest) =>
              new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime(),
          ),
        ],
        financingRequestPreview: undefined,
        loading: [...removeFromArrayIfExists(state.loading, actions.GET_FINANCINGREQ_REQUEST)],
      };
    case actions.GET_FINANCINGREQ_ERROR:
      return {
        ...state,
        loading: [...removeFromArrayIfExists(state.loading, actions.GET_FINANCINGREQ_REQUEST)],
        errors: [...addToArrayIfNotExists(state.errors, actions.GET_FINANCINGREQ_REQUEST)],
      };
    // PATCH
    case actions.PATCH_FINANCINGREQ_REQUEST:
      return {
        ...state,
        loading: [...addToArrayIfNotExists(state.loading, actions.PATCH_FINANCINGREQ_REQUEST)],
        errors: [...removeFromArrayIfExists(state.errors, actions.PATCH_FINANCINGREQ_REQUEST)],
      };
    case actions.PATCH_FINANCINGREQ_SUCCESS:
      return {
        ...state,
        financingRequests: [...mergeByKey('resourceId', state.financingRequests, [payload])].sort(
          (a, b) => b.updatedAt - a.updatedAt,
        ),
        loading: [...removeFromArrayIfExists(state.loading, actions.PATCH_FINANCINGREQ_REQUEST)],
      };
    case actions.PATCH_FINANCINGREQ_ERROR:
      return {
        ...state,
        loading: [...removeFromArrayIfExists(state.loading, actions.PATCH_FINANCINGREQ_REQUEST)],
        errors: [...addToArrayIfNotExists(state.errors, actions.PATCH_FINANCINGREQ_REQUEST)],
      };

    // POST
    case actions.POST_FINANCINGREQ_REQUEST:
      return {
        ...state,
        loading: [...addToArrayIfNotExists(state.loading, actions.POST_FINANCINGREQ_REQUEST)],
        errors: [...removeFromArrayIfExists(state.errors, actions.POST_FINANCINGREQ_REQUEST)],
      };
    case actions.POST_FINANCINGREQ_SUCCESS:
      return {
        ...state,
        financingRequests: [...mergeByKey('resourceId', state.financingRequests, [payload])].sort(
          (a, b) => b.updatedAt - a.updatedAt,
        ),
        loading: [...removeFromArrayIfExists(state.loading, actions.POST_FINANCINGREQ_REQUEST)],
      };
    case actions.POST_FINANCINGREQ_ERROR:
      return {
        ...state,
        loading: [...removeFromArrayIfExists(state.loading, actions.POST_FINANCINGREQ_REQUEST)],
        errors: [...addToArrayIfNotExists(state.errors, actions.POST_FINANCINGREQ_REQUEST)],
      };

    // LIST
    case actions.LIST_FINANCINGREQ_REQUEST:
      return {
        ...state,
        loading: [...addToArrayIfNotExists(state.loading, actions.LIST_FINANCINGREQ_REQUEST)],
        errors: [...removeFromArrayIfExists(state.errors, actions.LIST_FINANCINGREQ_REQUEST)],
      };
    case actions.LIST_FINANCINGREQ_SUCCESS:
      return {
        ...state,
        financingRequests: [...mergeByKey('resourceId', state.financingRequests, payload)].sort(
          (a, b) => b.updatedAt - a.updatedAt,
        ),
        loading: [...removeFromArrayIfExists(state.loading, actions.LIST_FINANCINGREQ_REQUEST)],
      };
    case actions.LIST_FINANCINGREQ_ERROR:
      return {
        ...state,
        loading: [...removeFromArrayIfExists(state.loading, actions.LIST_FINANCINGREQ_REQUEST)],
        errors: [...addToArrayIfNotExists(state.errors, actions.LIST_FINANCINGREQ_REQUEST)],
      };

    // POST_SENDFINANCINGREQ_
    case actions.POST_SENDFINANCINGREQ_REQUEST:
      return {
        ...state,
        loading: [...addToArrayIfNotExists(state.loading, actions.POST_SENDFINANCINGREQ_REQUEST)],
        errors: [...removeFromArrayIfExists(state.errors, actions.POST_SENDFINANCINGREQ_REQUEST)],
      };
    case actions.POST_SENDFINANCINGREQ_SUCCESS:
      return {
        ...state,
        financingRequests: [...mergeByKey('resourceId', state.financingRequests, [payload])].sort(
          (a, b) => b.updatedAt - a.updatedAt,
        ),
        loading: [...removeFromArrayIfExists(state.loading, actions.POST_SENDFINANCINGREQ_REQUEST)],
      };
    case actions.POST_SENDFINANCINGREQ_ERROR:
      return {
        ...state,
        loading: [...removeFromArrayIfExists(state.loading, actions.POST_SENDFINANCINGREQ_REQUEST)],
        errors: [...addToArrayIfNotExists(state.errors, actions.POST_SENDFINANCINGREQ_REQUEST)],
      };

    // DELETE
    case actions.DELETE_FINANCINGREQ_REQUEST:
      return {
        ...state,
        loading: [...addToArrayIfNotExists(state.loading, actions.DELETE_FINANCINGREQ_REQUEST)],
        errors: [...removeFromArrayIfExists(state.errors, actions.DELETE_FINANCINGREQ_REQUEST)],
      };
    case actions.DELETE_FINANCINGREQ_SUCCESS:
      return {
        ...state,
        financingRequests: [
          ...state.financingRequests.filter((c) => c.resourceId !== payload?.resourceId),
        ],
        loading: [...removeFromArrayIfExists(state.loading, actions.DELETE_FINANCINGREQ_REQUEST)],
      };
    case actions.DELETE_FINANCINGREQ_ERROR:
      return {
        ...state,
        loading: [...removeFromArrayIfExists(state.loading, actions.DELETE_FINANCINGREQ_REQUEST)],
        errors: [...addToArrayIfNotExists(state.errors, actions.DELETE_FINANCINGREQ_REQUEST)],
      };
    // GET_FINANCINGREQPREV
    case actions.GET_FINANCINGREQPREV_REQUEST:
      return {
        ...state,
        loading: [...addToArrayIfNotExists(state.loading, actions.GET_FINANCINGREQPREV_REQUEST)],
        errors: [...removeFromArrayIfExists(state.errors, actions.GET_FINANCINGREQPREV_REQUEST)],
      };
    case actions.GET_FINANCINGREQPREV_SUCCESS:
      return {
        ...state,
        financingRequestPreview: payload,
        loading: [...removeFromArrayIfExists(state.loading, actions.GET_FINANCINGREQPREV_REQUEST)],
      };
    case actions.GET_FINANCINGREQPREV_ERROR:
      return {
        ...state,
        loading: [...removeFromArrayIfExists(state.loading, actions.GET_FINANCINGREQPREV_REQUEST)],
        errors: [...addToArrayIfNotExists(state.errors, actions.GET_FINANCINGREQPREV_REQUEST)],
      };
    default:
      return state;
  }
}

