enum actions {
  // OTHERS
  SET_ROLES = "SET_ROLES",
  ADD_PERMISSIONS = "ADD_PERMISSIONS",
  SET_MODULES = "SET_MODULES",

  SEND_NOTIFICATION = "SEND_NOTIFICATION",

  COGNITO_SIGN_IN = "COGNITO_SIGN_IN",
  COGNITO_SIGN_OUT = "COGNITO_SIGN_OUT",
  COGNITO_RESET_PASSWORD = "COGNITO_RESET_PASSWORD",

  // AUTH
  POST_VERIFY_EMAIL_REQUEST = "POST_VERIFY_EMAIL_REQUEST",
  POST_VERIFY_EMAIL_SUCCESS = "POST_VERIFY_EMAIL_SUCCESS",
  POST_VERIFY_EMAIL_ERROR = "POST_VERIFY_EMAIL_ERROR",

  POST_RESEND_VERIFY_EMAIL_REQUEST = "POST_RESEND_VERIFY_EMAIL_REQUEST",
  POST_RESEND_VERIFY_EMAIL_SUCCESS = "POST_RESEND_VERIFY_EMAIL_SUCCESS",
  POST_RESEND_VERIFY_EMAIL_ERROR = "POST_RESEND_VERIFY_EMAIL_ERROR",

  // QUOTE
  GET_QUOTE_REQUEST = "GET_QUOTE_REQUEST",
  GET_QUOTE_SUCCESS = "GET_QUOTE_SUCCESS",
  GET_QUOTE_ERROR = "GET_QUOTE_ERROR",

  POST_UPLOADQUOTE_REQUEST = "POST_UPLOADQUOTE_REQUEST",
  POST_UPLOADQUOTE_SUCCESS = "POST_UPLOADQUOTE_SUCCESS",
  POST_UPLOADQUOTE_ERROR = "POST_UPLOADQUOTE_ERROR",

  POST_QUOTE_REQUEST = "POST_QUOTE_REQUEST",
  POST_QUOTE_SUCCESS = "POST_QUOTE_SUCCESS",
  POST_QUOTE_ERROR = "POST_QUOTE_ERROR",

  PATCH_QUOTE_REQUEST = "PATCH_QUOTE_REQUEST",
  PATCH_QUOTE_SUCCESS = "PATCH_QUOTE_SUCCESS",
  PATCH_QUOTE_ERROR = "PATCH_QUOTE_ERROR",

  DELETE_QUOTE_REQUEST = "DELETE_QUOTE_REQUEST",
  DELETE_QUOTE_SUCCESS = "DELETE_QUOTE_SUCCESS",
  DELETE_QUOTE_ERROR = "DELETE_QUOTE_ERROR",

  LIST_QUOTE_REQUEST = "LIST_QUOTE_REQUEST",
  LIST_QUOTE_SUCCESS = "LIST_QUOTE_SUCCESS",
  LIST_QUOTE_ERROR = "LIST_QUOTE_ERROR",
  
  // FINANCINGREQ
  GET_FINANCINGREQ_REQUEST = "GET_FINANCINGREQ_REQUEST",
  GET_FINANCINGREQ_SUCCESS = "GET_FINANCINGREQ_SUCCESS",
  GET_FINANCINGREQ_ERROR = "GET_FINANCINGREQ_ERROR",

  POST_FINANCINGREQ_REQUEST = "POST_FINANCINGREQ_REQUEST",
  POST_FINANCINGREQ_SUCCESS = "POST_FINANCINGREQ_SUCCESS",
  POST_FINANCINGREQ_ERROR = "POST_FINANCINGREQ_ERROR",

  PATCH_FINANCINGREQ_REQUEST = "PATCH_FINANCINGREQ_REQUEST",
  PATCH_FINANCINGREQ_SUCCESS = "PATCH_FINANCINGREQ_SUCCESS",
  PATCH_FINANCINGREQ_ERROR = "PATCH_FINANCINGREQ_ERROR",

  DELETE_FINANCINGREQ_REQUEST = "DELETE_FINANCINGREQ_REQUEST",
  DELETE_FINANCINGREQ_SUCCESS = "DELETE_FINANCINGREQ_SUCCESS",
  DELETE_FINANCINGREQ_ERROR = "DELETE_FINANCINGREQ_ERROR",

  LIST_FINANCINGREQ_REQUEST = "LIST_FINANCINGREQ_REQUEST",
  LIST_FINANCINGREQ_SUCCESS = "LIST_FINANCINGREQ_SUCCESS",
  LIST_FINANCINGREQ_ERROR = "LIST_FINANCINGREQ_ERROR",

  GET_FINANCINGREQPREV_REQUEST = "GET_FINANCINGREQPREV_REQUEST",
  GET_FINANCINGREQPREV_SUCCESS = "GET_FINANCINGREQPREV_SUCCESS",
  GET_FINANCINGREQPREV_ERROR = "GET_FINANCINGREQPREV_ERROR",
  
  // FINANCINGREQ
  POST_SENDFINANCINGREQ_REQUEST = "POST_SENDFINANCINGREQ_REQUEST",
  POST_SENDFINANCINGREQ_SUCCESS = "POST_SENDFINANCINGREQ_SUCCESS",
  POST_SENDFINANCINGREQ_ERROR = "POST_SENDFINANCINGREQ_ERROR",

  // UI
  ACTIVATE_GLOBAL_LOADING = "ACTIVATE_GLOBAL_LOADING",
  DEACTIVATE_GLOBAL_LOADING = "DEACTIVATE_GLOBAL_LOADING",

  OPEN_SLIDE_OVER = "OPEN_SLIDE_OVER",
  CLOSE_SLIDE_OVER = "CLOSE_SLIDE_OVER",
  OPEN_MODAL = "OPEN_MODAL",
  CLOSE_MODAL = "CLOSE_MODAL",


  // USER
  GET_USER_REQUEST = "GET_USER_REQUEST",
  GET_USER_SUCCESS = "GET_USER_SUCCESS",
  GET_USER_ERROR = "GET_USER_ERROR",

  PATCH_USER_REQUEST = "PATCH_USER_REQUEST",
  PATCH_USER_SUCCESS = "PATCH_USER_SUCCESS",
  PATCH_USER_ERROR = "PATCH_USER_ERROR",

  // BUSINESS
  GET_BUSINESS_REQUEST = "GET_BUSINESS_REQUEST",
  GET_BUSINESS_SUCCESS = "GET_BUSINESS_SUCCESS",
  GET_BUSINESS_ERROR = "GET_BUSINESS_ERROR",

  PATCH_BUSINESS_REQUEST = "PATCH_BUSINESS_REQUEST",
  PATCH_BUSINESS_SUCCESS = "PATCH_BUSINESS_SUCCESS",
  PATCH_BUSINESS_ERROR = "PATCH_BUSINESS_ERROR",

  // CLIENT
  GET_CLIENT_REQUEST = "GET_CLIENT_REQUEST",
  GET_CLIENT_SUCCESS = "GET_CLIENT_SUCCESS",
  GET_CLIENT_ERROR = "GET_CLIENT_ERROR",

  POST_CLIENT_REQUEST = "POST_CLIENT_REQUEST",
  POST_CLIENT_SUCCESS = "POST_CLIENT_SUCCESS",
  POST_CLIENT_ERROR = "POST_CLIENT_ERROR",

  PATCH_CLIENT_REQUEST = "PATCH_CLIENT_REQUEST",
  PATCH_CLIENT_SUCCESS = "PATCH_CLIENT_SUCCESS",
  PATCH_CLIENT_ERROR = "PATCH_CLIENT_ERROR",

  DELETE_CLIENT_REQUEST = "DELETE_CLIENT_REQUEST",
  DELETE_CLIENT_SUCCESS = "DELETE_CLIENT_SUCCESS",
  DELETE_CLIENT_ERROR = "DELETE_CLIENT_ERROR",

  LIST_CLIENT_REQUEST = "LIST_CLIENT_REQUEST",
  LIST_CLIENT_SUCCESS = "LIST_CLIENT_SUCCESS",
  LIST_CLIENT_ERROR = "LIST_CLIENT_ERROR",
}

export default actions;
